import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import reduxStore, { setToast } from './app/Toast/ToastRedux';
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

const useMSW = process.env.REACT_APP_MSW;

if (process.env.NODE_ENV === 'development' && useMSW) {
  const { worker } = require('./mocks/browser');
  worker.start({ onUnhandledRequest: 'bypass' });
}

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('sessionId');

  return {
    headers: {
      ...headers,
      authorization: token ? `bearer ${token}` : null,
    },
  };
});

export const cache = new InMemoryCache({
  typePolicies: {
    Calendar: {
      fields: {
        locations: {
          merge(existing, incoming) {
            return incoming ? incoming : existing;
          },
        },
        events: {
          merge(existing, incoming) {
            return incoming ? incoming : existing;
          },
        },
        specifiedUsers: {
          merge(existing, incoming) {
            return incoming ? incoming : existing;
          },
        },
      },
    },
  },
  possibleTypes: {
    User: ['FullUser', 'VerifyUser', 'GuestUser'],
  },
});

const errorLink = onError(({ networkError }) => {
  if (networkError) {
    setToast(
      "Couldn't connect to the server. Are you connected to the internet?",
    );
  }
});

const port = useMSW ? 3000 : 4000;

const httpLink = new HttpLink({
  uri:
    process.env.NODE_ENV === 'production'
      ? '/graphql'
      : `http://localhost:${port}/graphql`,
  // Use explicit `window.fetch` so tha outgoing requests
  // are captured and deferred until the Service Worker is ready.
  fetch: (...args) => fetch(...args),
});

const client = new ApolloClient({
  cache,
  link: from([errorLink, authLink, httpLink]),
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={reduxStore}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
);
