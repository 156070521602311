import { Form, Formik } from 'formik';
import {
  NotificationBanner,
  ProtectedField,
  TextField,
} from '../utilities/formfields';
import { GrayButton, PrimaryButton } from '../utilities/buttons';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useStatus from '../../hooks/useStatus';
import {
  useLoginMutation,
  useVerifyCodeMutation,
} from '../../graphql/mutations';
import loginValidationSchema from '../../schemas/loginSchema';
import { onSubmitHandleError } from '../onSubmitHandleError';
import { useEffect } from 'react';

const Login = ({
  setCurrEmail,
}: {
  setCurrEmail: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const { status, setNewStatus } = useStatus();
  const [login] = useLoginMutation();
  const [verifyCode] = useVerifyCodeMutation();

  const verificationCode = searchParams.get('verify');
  useEffect(() => {
    if (verificationCode) {
      const sendCode = async () => {
        try {
          const { data } = await verifyCode({
            variables: { code: verificationCode },
          });
          if (data && data.verifyCode === true) {
            setNewStatus({ text: 'Verified account', type: 'success' }, 5000);
          } else {
            throw new Error();
          }
        } catch {
          setNewStatus(
            {
              text: 'Verification code is invalid. Log in again to send a new code',
              type: 'failure',
            },
            5000,
          );
        }
      };
      sendCode();
    }
  }, []);

  return (
    <>
      <p className="text-h1 text-2xl font-bold mt-4 mb-6">Log in</p>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={loginValidationSchema}
        onSubmit={(values, submitProps) => {
          onSubmitHandleError<typeof values>(
            async () => {
              const res = await login({
                variables: { email: values.email, password: values.password },
              });

              if (res.data) {
                localStorage.setItem('sessionId', res.data.login.value);

                navigate(location.state ? (location.state as any).from : '/');

                window.history.replaceState({}, document.title);
              } else {
                setNewStatus(
                  {
                    type: 'failure',
                    text: 'No data returned',
                  },
                  5000,
                );
              }
            },
            setNewStatus,
            submitProps,
            (e) => {
              if (e.message === "Account hasn't been verified yet") {
                setCurrEmail(values.email);
              } else {
                setNewStatus(
                  {
                    type: 'failure',
                    text: e.message,
                  },
                  5000,
                );
              }
            },
          );
        }}
      >
        {(formik) => (
          <Form className="w-full">
            <TextField
              label="Email"
              name="email"
              type="text"
              placeholder="myemail@gmail.com"
              disabled={formik.isSubmitting}
            />
            <ProtectedField
              label="Password"
              name="password"
              type="password"
              placeholder="password"
              disabled={formik.isSubmitting}
            />
            <NotificationBanner notificationInfo={status} />
            <div className="grid grid-cols-2 gap-4 mt-8 mb-3">
              <GrayButton
                text="Cancel"
                onClick={() => navigate('/')}
                disabled={formik.isSubmitting}
              />
              <PrimaryButton
                text="Log in"
                disabled={formik.isSubmitting}
                type="submit"
                status={formik.isSubmitting ? 'loading' : 'default'}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Login;
