import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import {
  BasicLocationResults,
  useCalendarById,
  useMeQuery,
} from '../../graphql/queries';
import { CogIcon } from '../../Icons';
import { GrayButton, PrimaryButton, TextButton } from '../utilities/buttons';

const LocationCard = ({
  locationData,
  navigate,
  owner,
}: {
  locationData: BasicLocationResults;
  navigate: NavigateFunction;
  owner: boolean;
}) => (
  <div
    className="relative border-2 border-teal-800 flex flex-col items-center h-36 w-28 rounded-md p-2 
  md:h-64 md:w-48"
    style={{ borderColor: locationData.color }}
  >
    <h2
      className="text-sm text-center text-teal-800 max-w-[6rem] break-words text-ellipsis line-clamp-2
      md:text-lg md:max-w-[10rem]"
      style={{ color: locationData.color }}
    >
      {locationData.name}
    </h2>
    <div className='hidden md:block'>
      <p className="font-light text-body max-w-[8rem] break-words text-ellipsis text-center line-clamp-3 mt-2">
        {locationData.description}
      </p>
    </div>
    {owner && (
      <button
        className="absolute right-1 top-1"
        onClick={() => navigate(`${locationData.miniId}/settings`)}
      >
        <CogIcon />
      </button>
    )}
    <TextButton
      text="Go"
      customButtonClasses="w-4/5 absolute bottom-2"
      customButtonStyle={{ backgroundColor: locationData.color }}
      onClick={() => navigate(`${locationData.miniId}`)}
    />
  </div>
);

const CalendarLocations = () => {
  const selectedCalendarId = useParams().calendarId;
  if (!selectedCalendarId) return <h1>no id in url</h1>;

  const { data: userData, loading: userLoading } = useMeQuery();
  const {
    data: calendarData,
    loading,
    error,
  } = useCalendarById({ miniId: selectedCalendarId });
  const navigate = useNavigate();

  if (loading || userLoading) return <></>;
  if (!calendarData || !calendarData.calendarByMiniId || error)
    return <h1>Data couldn't be loaded</h1>;

  const isOwner = userData
    ? calendarData.calendarByMiniId.owner.id === userData.me.id
    : false;
  return (
    <div className="flex flex-col items-center justify-between h-[calc(100%-56px)]">
      <div>
        <h1 className="text-h1 text-xl font-bold text-center mt-2 mb-6">
          Locations
        </h1>
        <div className="flex flex-wrap justify-center gap-8 px-8">
          {calendarData.calendarByMiniId.locations.map((locationData) => (
            <LocationCard
              locationData={locationData}
              owner={isOwner}
              navigate={navigate}
              key={locationData.id}
            />
          ))}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-8 mt-8 mb-6 w-full px-8">
        <GrayButton
          text="Back"
          onClick={() => navigate('../..')}
          customButtonClasses={isOwner ? '' : 'col-span-2'}
        />
        {isOwner && (
          <PrimaryButton
            text="New location"
            onClick={() => navigate('newlocation')}
          />
        )}
      </div>
    </div>
  );
};

export default CalendarLocations;
