import * as Yup from 'yup';
import { sameDay } from './utilities';

const validateTime = (
  startTime: string | undefined,
  endTime: string | undefined,
  startDate: Date | undefined,
  endDate: Date | undefined,
) => {
  if (startTime && endTime && startDate && endDate) {
    if (sameDay(startDate, endDate)) {
      const startHour = Number(startTime.substring(0, 2));
      const endHour = Number(endTime.substring(0, 2));

      if (startHour > endHour) {
        return false;
      } else if (startHour == endHour) {
        const startMin = Number(startTime.substring(3, 5));
        const endMin = Number(endTime.substring(3, 5));

        return startMin < endMin;
      } else {
        return true;
      }
    } else {
      return startDate < endDate;
    }
  }
  return true;
};

const sharedSchema = {
  title: Yup.string()
    .required('Title required')
    .min(1, 'Minimum of 1 character')
    .max(50, 'Maximum of 50 characters'),
  names: Yup.string()
    .required('Name(s) required')
    .min(1, 'Minimum of 1 character')
    .max(200, 'Maximum of 200 characters'),
};

const eventClientValidationSchema = Yup.object({
  ...sharedSchema,
  startDate: Yup.date()
    .required('Start date required')
    .test(
      'before end date',
      'End date is before start date',
      function (startDate) {
        return startDate !== undefined
          ? startDate <= this.parent.endDate
          : false;
      },
    ),
  startTime: Yup.string()
    .required('Start time required')
    .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'Invalid format (hh/mm)'),
  endDate: Yup.date()
    .required('End date required')
    .test(
      'after start date',
      'End date is before start date',
      function (endDate) {
        return endDate !== undefined ? endDate >= this.parent.startDate : false;
      },
    ),
  endTime: Yup.string()
    .required('End time required')
    .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'Invalid format (hh/mm)')
    .test(
      'after start time',
      'End time is before start date',
      function (endTime) {
        return validateTime(
          this.parent.startTime,
          endTime,
          this.parent.startDate,
          this.parent.endDate,
        );
      },
    ),
  selectedLocationI: Yup.string().required('Location required'),
});

const eventServerSchema = {
  ...sharedSchema,
  locationMiniId: Yup.string().required('Location id required'),
  startDate: Yup.number()
    .required('Start date required')
    .test(
      'before end date',
      'End date is before start date',
      function (startDate) {
        return startDate !== undefined
          ? startDate < this.parent.endDate
          : false;
      },
    )
    .test(
      'at least a minute long',
      'Reservation must be at least a minute long',
      function (startDate) {
        return startDate !== undefined
          ? this.parent.endDate - startDate >= 60000
          : false;
      },
    ),
  endDate: Yup.number().required('End date required'),
};

const eventServerCreateValidationSchema = Yup.object({
  ...eventServerSchema,
  calendarMiniId: Yup.string().required('Calendar id required'),
});

const eventServerModifyValidationSchema = Yup.object({
  ...eventServerSchema,
  eventId: Yup.string().required('Event id required'),
});

const eventServerDeleteValidationSchema = Yup.object({
  eventId: Yup.string().required('Event id required'),
});

export {
  eventClientValidationSchema,
  eventServerCreateValidationSchema,
  eventServerModifyValidationSchema,
  eventServerDeleteValidationSchema,
};
