import { useState } from 'react';
import { useNewCodeMutation } from '../../graphql/mutations';
import { PlaneIcon } from '../../Icons';
import { PrimaryButton, TextButtonStatus } from '../utilities/buttons';
import { NotificationBanner } from '../utilities/formfields';

const VerifyEmail = ({ email }: { email: string }) => {
  const [status, setStatus] = useState<TextButtonStatus | 'error'>('default');
  const [currTimeout, setCurrTimeout] = useState<NodeJS.Timeout>();
  const [newCode] = useNewCodeMutation();

  const resendEmail = async () => {
    clearTimeout(currTimeout);
    setStatus('loading');

    try {
      const { data } = await newCode({ variables: { email } });

      if (data && data.newCode === true) {
        setStatus('success');
      } else {
        throw new Error();
      }
    } catch {
      setStatus('error');
    }

    // dismiss in 5 sec
    setCurrTimeout(
      setTimeout(() => {
        setStatus('default');
      }, 5000),
    );
  };

  return (
    <>
      <p className="text-h1 text-2xl font-bold mt-4 mb-8">Create an account</p>
      <p className="text-body mb-10">
        Please confirm your email by clicking on the verfication link in an
        email we sent to you
      </p>
      <p className="font-light text-body2">
        Didn't receive an email? Click the{' '}
        <span className="font-bold">resend</span> button below to resend the
        verification email
      </p>
      <div className="w-full my-1">
        <NotificationBanner
          notificationInfo={
            status === 'error'
              ? { text: "Couldn't send new verification code", type: 'failure' }
              : undefined
          }
        />
      </div>

      <PrimaryButton
        text={status === 'success' ? 'Re-sent' : 'Resend'}
        customButtonClasses="w-full mb-4"
        defaultIcon={<PlaneIcon />}
        status={status === 'error' ? 'default' : status}
        onClick={resendEmail}
        disabled={status === 'success' || status === 'error'}
      />
    </>
  );
};

export default VerifyEmail;
