import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMeQuery } from '../../graphql/queries';
import {
  CalendarIcon,
  CogIcon,
  FilterIcon,
  LocationIcon,
  MenuIcon,
  XButton,
} from '../../Icons';
import { CalendarBackground, calendarLengths } from '../Calendar/Calendar';
import {
  incrementCalendarLengthI,
  setActiveDrawer,
  setSelectedEventId,
  setShowMenu,
  useUISelector,
} from '../Calendar/CalendarRedux';
import { PrimaryButton } from '../utilities/buttons';
import Menu from './Menu';

interface NavButtonProps {
  children: JSX.Element;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

interface HeaderProps {
  headerType: 'calendar' | 'create account' | 'secondary';
  setCalendarBackground?: React.Dispatch<
    React.SetStateAction<CalendarBackground>
  >;
  owner?: string;
}

const NavButton = ({ children, onClick }: NavButtonProps) => {
  return <button onClick={onClick}>{children}</button>;
};

const ResercateLogo = () => (
  <div>
    <div className="flex items-center gap-x-2">
      <CalendarIcon />
      <h1 className="text-h1">resercate</h1>
    </div>
  </div>
);

const Header = ({ headerType, setCalendarBackground, owner }: HeaderProps) => {
  const { data: userData, loading, error } = useMeQuery();

  const { showMenu, calendarLengthI, activeDrawer } = useUISelector(
    (state) => state.ui,
  );
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const isOwner =
    headerType === 'calendar' &&
    !loading &&
    !error &&
    userData &&
    userData.me.accountState === 'USER' &&
    userData.me.id === owner;

  return (
    <div className="relative">
      <header className="p-4 flex justify-between items-center h-14">
        <div className="flex items-center gap-x-4">
          <NavButton
            onClick={() => {
              if (setCalendarBackground) {
                if (showMenu) {
                  setCalendarBackground('none');
                } else {
                  setCalendarBackground('darken');
                }
              }
              dispatch(setShowMenu(!showMenu));
            }}
          >
            {showMenu ? <XButton /> : <MenuIcon />}
          </NavButton>
          <NavButton
            onClick={() => {
              dispatch(setShowMenu(false));
              dispatch(setSelectedEventId(undefined));
              navigate('/');
            }}
          >
            <ResercateLogo />
          </NavButton>
        </div>
        <div className="flex items-center gap-x-4">
          {headerType === 'create account' && (
            <PrimaryButton
              customTextClasses="font-bold !text-xs"
              text="Create account"
              onClick={() => navigate('/signup')}
            />
          )}
          {headerType === 'calendar' && (
            <>
              {isOwner && (
                <NavButton onClick={() => navigate('settings')}>
                  <CogIcon />
                </NavButton>
              )}
            </>
          )}
          {(headerType === 'create account' || headerType === 'calendar') && (
            <>
              <NavButton
                onClick={() => {
                  dispatch(incrementCalendarLengthI(calendarLengths.length));
                }}
              >
                <div className="bg-h1 w-6 h-6 rounded-full flex items-center justify-center">
                  <p className="text-white text-sm">
                    {calendarLengths[calendarLengthI]}
                  </p>
                </div>
              </NavButton>
              <NavButton onClick={() => navigate('locations')}>
                <LocationIcon />
              </NavButton>
              <NavButton
                onClick={() => {
                  dispatch(
                    setActiveDrawer(
                      activeDrawer === 'filter' ? 'none' : 'filter',
                    ),
                  );
                  dispatch(setSelectedEventId(undefined));
                }}
              >
                <FilterIcon />
              </NavButton>
            </>
          )}
        </div>
      </header>
      {showMenu && <Menu />}
    </div>
  );
};

export default Header;
