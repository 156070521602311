const fallbackCopyTextToClipboard = (text: string) => {
  var textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  let successful;
  try {
    successful = document.execCommand('copy');
  } catch (err) {
    successful = false;
  }

  document.body.removeChild(textArea);
  return successful;
};

export const copyTextToClipboard = async (text: string) => {
  if (!navigator.clipboard) {
    const successful = fallbackCopyTextToClipboard(text);
    return successful;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const successful = await navigator.clipboard.writeText(text).catch(e => false);
  return successful;
};
