import * as Yup from 'yup';

const sharedSchema = {
  name: Yup.string()
    .trim()
    .required('Name is required')
    .min(1, 'Minimum of 1 character')
    .max(50, 'Maximum of 50 characters'),
  location: Yup.string()
    .required('Location is required')
    .min(1, 'Minimum of 1 character')
    .max(100, 'Maximum of 100 characters'),
  address: Yup.string()
    .required('Address is required')
    .min(1, 'Minimum of 1 character')
    .max(100, 'Maximum of 100 characters'),
  description: Yup.string().trim().max(300, 'Maximum of 300 characters'),
};

const locationClientValidationSchema = Yup.object({
  ...sharedSchema,
  name: sharedSchema.name.test(
    'unique location',
    'A location with the same name already exists',
    function test(value) {
      return this.options.context !== undefined && value !== undefined
        ? this.options.context.locationNames.every(
            (n: string) => n.toLowerCase() !== value.toLowerCase(),
          )
        : false;
    },
  ),
});

const locationServerCreateValidationSchema = Yup.object({
  ...sharedSchema,
  color: Yup.string()
    .required('Color is required')
    .test('valid hex color code', 'Color must be a valid hex color code', (v) =>
      v !== undefined ? /^#[0-9A-F]{6}$/i.test(v) : false,
    ),
  calendarMiniId: Yup.string().required('Calendar id is required'),
});

const locationServerModifyValidationSchema = Yup.object({
  ...sharedSchema,
  color: Yup.string()
    .required('Color is required')
    .test('valid hex color code', 'Color must be a valid hex color code', (v) =>
      v !== undefined ? /^#[0-9A-F]{6}$/i.test(v) : false,
    ),
  calendarMiniId: Yup.string().required('Calendar id is required'),
  locationMiniId: Yup.string().required('Location id is required'),
});

const locationServerDeleteValidationSchema = Yup.object({
  calendarMiniId: Yup.string().required('Calendar id is required'),
  locationMiniId: Yup.string().required('Location id is required'),
});

export {
  locationClientValidationSchema,
  locationServerCreateValidationSchema,
  locationServerModifyValidationSchema,
  locationServerDeleteValidationSchema,
};
