import { FormikHelpers } from 'formik';
import { GraphQLError } from 'graphql';
import { NotificationInfo } from '../hooks/useStatus';

export async function onSubmitHandleError<Type>(
  tryFunc: (submitProps?: FormikHelpers<Type>) => Promise<void>,
  setNewStatus: (newStatus: NotificationInfo, timeout?: number) => void,
  submitProps?: FormikHelpers<Type>,
  onErr?: (e: Error) => void,
) {
  try {
    await tryFunc(submitProps);
  } catch (e) {
    if (onErr && e instanceof Error) {
      onErr(e);
    } else if (e instanceof GraphQLError) {
      setNewStatus(
        {
          type: 'failure',
          text: e.message,
        },
        5000,
      );
    } else if (
      (e as any).networkError &&
      (e as any).networkError.result &&
      (e as any).networkError.result.errors
    ) {
      setNewStatus(
        {
          type: 'failure',
          text: (e as any).networkError.result.errors[0].message,
        },
        5000,
      );
    } else if (e instanceof Error) {
      setNewStatus(
        {
          type: 'failure',
          text: e.message,
        },
        5000,
      );
    } else {
      setNewStatus(
        {
          type: 'failure',
          text: 'An unknown error occurred',
        },
        5000,
      );
    }
  }
  submitProps?.setSubmitting(false);
}
