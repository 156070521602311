import { BasicEventResults, BasicLocationResults } from '../../graphql/queries';

interface PartialEventComponentInfo {
  eventId: string;
  title: string;
  top: number;
  height: number;
  color: string;
  startsOnThisDay: boolean;
  endsOnThisDay: boolean;
}

export interface EventComponentInfo extends PartialEventComponentInfo {
  width: string;
  left: string;
}

// type PartialEventComponentInfos = {
//   [dateTime: number]: [PartialEventComponentInfo];
// };

export type EventComponentInfos = { [dateTime: number]: [EventComponentInfo] };

export const eventsToEventComponentInfos = (
  events: BasicEventResults[],
  filteredLocations: BasicLocationResults[],
) => {
  let info: EventComponentInfos = {};

  for (const event of events) {
    const eventStartDate = new Date(event.startDate);
    const eventEndDate = new Date(event.endDate);

    let startDate = new Date(eventStartDate.getTime());
    startDate.setHours(0, 0, 0, 0);

    let endDate = new Date(eventEndDate.getTime());
    endDate.setHours(0, 0, 0, 0);

    for (
      let currDate = startDate;
      currDate < eventEndDate;
      currDate.setDate(currDate.getDate() + 1)
    ) {
      let top = 0;
      let startsOnThisDay = false;
      if (currDate <= eventStartDate) {
        top =
          (eventStartDate.getHours() + eventStartDate.getMinutes() / 60) * 40;
        startsOnThisDay = true;
      }

      let height = 24 * 40 - top;
      let endsOnThisDay = false;
      if (currDate.getTime() === endDate.getTime()) {
        height =
          (eventEndDate.getHours() + eventEndDate.getMinutes() / 60) * 40 - top;
        endsOnThisDay = true;
      }

      // edge case
      if (
        eventEndDate.getHours() === 0 &&
        eventEndDate.getMinutes() === 0 &&
        eventEndDate.getSeconds() === 0
      ) {
        endsOnThisDay = true;
      }

      const eventComponentInfo: EventComponentInfo = {
        eventId: event.id,
        title: event.title,
        top,
        height,
        left: `${
          (filteredLocations.findIndex((l) => l.id === event.location.id) *
            100) /
          filteredLocations.length
        }%`,
        width: `${100 / filteredLocations.length}%`,
        startsOnThisDay,
        endsOnThisDay,
        color: event.location.color,
      };

      if (info[currDate.getTime()]) {
        info[currDate.getTime()].push(eventComponentInfo);
      } else {
        info[currDate.getTime()] = [eventComponentInfo];
      }
    }
  }

  // let modifiedInfo: EventComponentInfos = {};
  // for (let d of Object.keys(info)) {
  //   const dateTime = Number(d);
  //   const partialInfos = info[dateTime];

  //   modifiedInfo[dateTime] = [
  //     {
  //       ...partialInfos[0],
  //       width: '100%',
  //       left: '0%',
  //     },
  //   ];

  //   for (let i = 0; i < partialInfos.length; i++) {
  //     // check for collisions with added elements
  //   }
  // }

  return info;
};

export const dateToFormat = (date: Date) =>
  date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();

export const getDay = (i: number, startDate: Date, isWeek: boolean) => {
  const abbrev = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

  let date;
  if (isWeek) {
    date = new Date(startDate);
    date.setDate(date.getDate() - ((date.getDay() + 6) % 7));
  } else {
    date = new Date(startDate);
  }
  date.setDate(date.getDate() + i);

  const mmdd = date.getMonth() + 1 + '/' + date.getDate();

  return {
    dayOfWeek: abbrev[date.getDay()],
    date: mmdd,
    fullDate: dateToFormat(date),
    dateObject: date,
  };
};

export const getHour = (i: number) => {
  // start at 1 am, end at 11 pm
  if (i == 23) return '';
  if (i <= 10) return `${i + 1} am`;
  if (i === 11) return '12 pm';
  if (i <= 22) return `${i - 11} pm`;
};

export const sameDay = (date1: Date, date2: Date) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};
