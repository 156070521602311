import { useNavigate, useParams } from 'react-router-dom';
import { useLocationById } from '../../graphql/queries';
import { GrayButton, PrimaryButton } from '../utilities/buttons';
// @ts-ignore
// import sampleImage from './sample_photo.jpg';

interface DataPointProps {
  title: string;
  value: string;
  customTextStyle?: React.CSSProperties;
}

const DataPoint = ({ title, value, customTextStyle = {} }: DataPointProps) => (
  <>
    <h3 className="text-sm text-body font-semibold">{title}</h3>
    <p className="text-sm text-body2" style={customTextStyle}>
      {value}
    </p>
  </>
);

const LocationPage = () => {
  const selectedLocationId = useParams().locationId;
  const selectedCalendarId = useParams().calendarId;
  if (!selectedLocationId || !selectedCalendarId) return <h1>no id in url</h1>;

  const navigate = useNavigate();

  const {
    data: locationData,
    loading,
    error,
  } = useLocationById({
    locationMiniId: selectedLocationId,
    calendarMiniId: selectedCalendarId,
  });

  if (loading) return <></>;
  if (!locationData || error) return <h1>Data couldn't be loaded</h1>;

  const popularityColors = {
    LOW: '#22c55e',
    MEDIUM: '#f97316',
    HIGH: '#b91c1c',
  };

  const location = locationData.locationByMiniId;
  if (!location) return <h1>Location couldn't be found</h1>;

  return (
    <div className="flex flex-col items-center justify-between h-[calc(100%-56px)] px-8">
      <div>
        <h1 className="text-xl font-bold text-center mb-6">{location.name}</h1>
        {/* <div className="flex flex-col items-center mb-4">
          <img
            src={sampleImage}
            className="object-cover w-full max-w-2xl aspect-[3/2]"
          />
          <div className="flex overflow-auto max-w-2xl">
            <img
              src={sampleImage}
              className="object-cover w-1/4 max-w-[10.5rem] aspect-[3/2]"
            />
            <img
              src={sampleImage}
              className="object-cover w-1/4 max-w-[10.5rem] aspect-[3/2]"
            />
            <img
              src={sampleImage}
              className="object-cover w-1/4 max-w-[10.5rem] aspect-[3/2]"
            />
            <img
              src={sampleImage}
              className="object-cover w-1/4 max-w-[10.5rem] aspect-[3/2]"
            />
          </div>
        </div> */}
        <div className="grid grid-cols-[auto_1fr] gap-y-2 gap-x-6 mt-20"> 
          <DataPoint title="Location" value={location.location} />
          <DataPoint title="Address" value={location.address} />
          <DataPoint
            title="Popularity"
            value={
              location.popularity.charAt(0) +
              location.popularity.substring(1).toLowerCase()
            }
            customTextStyle={{ color: popularityColors[location.popularity] }}
          />
          <DataPoint title="Calendar" value={location.calendar.title} />
        </div>
        <p className="text-sm font-light mt-6">{location.description}</p>
      </div>
      <div className="grid grid-cols-2 gap-8 mt-8 pb-6 w-full px-8">
        <GrayButton text="Back" onClick={() => navigate('../')} />
        <PrimaryButton text="Modify" onClick={() => navigate('settings')} />
      </div>
    </div>
  );
};

export default LocationPage;
