import { ApolloClient, useApolloClient } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';
import { useMeQuery } from '../../graphql/queries';
import { AccountIcon, HomeIcon, LogoutIcon, ThreeBoxesIcon } from '../../Icons';
import { setShowMenu } from '../Calendar/CalendarRedux';

interface MenuItemProps {
  menuData: MenuData;
  lockGuest: boolean;
}

interface MenuData {
  title: string;
  icon: JSX.Element;
  onClick: (client: ApolloClient<object>, navigate?: NavigateFunction) => void;
}

const Modal = ({
  dispatch,
  children,
}: {
  dispatch: Dispatch<AnyAction>;
  children: JSX.Element;
}) => (
  <div className="absolute top-full w-full sm:w-64 bg-pureWhite z-[200]">
    {children}
    <div
      className="fixed bg-[rgba(0,0,0,0.4)] sm:bg-transparent h-full w-full"
      onClick={() => dispatch(setShowMenu(false))}
    ></div>
  </div>
);

const allMenuData: MenuData[] = [
  {
    title: 'Home',
    icon: <HomeIcon />,
    onClick: (client, navigate) => navigate && navigate('/'),
  },
  {
    title: 'Calendars',
    icon: <ThreeBoxesIcon />,
    onClick: (client, navigate) => navigate && navigate('/'),
  },
  {
    title: 'My account',
    icon: <AccountIcon />,
    onClick: (client, navigate) => navigate && navigate('/account'),
  },
  {
    title: 'Log out',
    icon: <LogoutIcon />,
    onClick: async (client: ApolloClient<object>) => {
      localStorage.removeItem('sessionId');
      sessionStorage.removeItem('sessionId');
      await client.resetStore();
    },
  },
];

const MenuItem = ({ menuData, lockGuest }: MenuItemProps) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <button
      onClick={() => {
        if (!lockGuest) {
          menuData.onClick(client, navigate);
          dispatch(setShowMenu(false));
        }
      }}
      className="flex items-center w-full justify-center py-1"
    >
      <span>{menuData.icon}</span>
      <p className="px-6 text-center text-lg text-body w-52">
        {menuData.title}{' '}
        {lockGuest && <span className="italic">- for verified accounts</span>}
      </p>
    </button>
  );
};

const Menu = () => {
  const dispatch = useDispatch();
  const { data: userData, loading: userLoading } = useMeQuery();

  if (userLoading) return <></>;

  if (!userData) {
    return (
      <Modal dispatch={dispatch}>
        <div>
          <hr />
          <div className="flex flex-col items-center justify-center py-2 sm:p-6">
            <MenuItem
              menuData={allMenuData[0]}
              key={allMenuData[0].title}
              lockGuest={true}
            />
          </div>
        </div>
      </Modal>
    );
  }

  const isGuest = userData.me.accountState === 'GUEST';

  return (
    <Modal dispatch={dispatch}>
      <div className='sm:border-gray-500 sm:border-r-2 sm:border-b-2 sm:border-t-2'>
        <hr className="sm:hidden" />
        <div className="flex flex-col items-center justify-center py-2 sm:p-6">
          {allMenuData.map((menuData) => (
            <MenuItem
              menuData={menuData}
              key={menuData.title}
              lockGuest={isGuest && menuData.title === 'My account'}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default Menu;
