/* eslint-disable @typescript-eslint/indent */
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  InstantFeedbackField,
  NotificationBanner,
  TextField,
} from '../utilities/formfields';
import { GrayButton, PrimaryButton } from '../utilities/buttons';
import { useEffect } from 'react';
import useStatus from '../../hooks/useStatus';
import signupValidationSchema, {
  multivalueValidation,
  usernameSchema,
  possibleUsernameErrors,
  passwordSchema,
  possiblePasswordErrors,
  MultipleFieldErrors,
} from '../../schemas/signupSchema';
import { useCreateUserMutation } from '../../graphql/mutations';
import { onSubmitHandleError } from '../onSubmitHandleError';

const Signup = ({
  setCurrEmail,
}: {
  setCurrEmail: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const [createUser] = useCreateUserMutation();
  const navigate = useNavigate();
  const { status, setNewStatus } = useStatus();

  return (
    <>
      <p className="text-h1 text-2xl font-bold mt-4 mb-6">Create an account</p>
      <Formik
        initialValues={{ fullName: '', username: '', email: '', password: '' }}
        validate={async (values) => {
          const errors: {
            username?: MultipleFieldErrors;
            password?: MultipleFieldErrors;
          } = {};

          const usernameErrors = await multivalueValidation(
            'username',
            values.username,
            usernameSchema,
            possibleUsernameErrors,
          );
          if (usernameErrors) {
            errors.username = usernameErrors;
          }

          const passwordErrors = await multivalueValidation(
            'password',
            values.password,
            passwordSchema,
            possiblePasswordErrors,
          );
          if (passwordErrors) {
            errors.password = passwordErrors;
          }

          return errors;
        }}
        validationSchema={signupValidationSchema}
        onSubmit={(values, submitProps) => {
          onSubmitHandleError<typeof values>(
            async () => {
              await createUser({ variables: values });

              setCurrEmail(values.email);
            },
            setNewStatus,
            submitProps,
          );
        }}
      >
        {(formik) => {
          useEffect(() => {
            formik.validateForm();
          }, []);

          return (
            <Form className="w-full">
              <TextField
                label="Full name"
                name="fullName"
                type="text"
                placeholder="First Last"
                disabled={formik.isSubmitting}
              />

              <TextField
                label="Email"
                name="email"
                type="email"
                placeholder="myemail@gmail.com"
                disabled={formik.isSubmitting}
              />

              <InstantFeedbackField
                label="Username"
                name="username"
                type="text"
                placeholder="myusername"
                disabled={formik.isSubmitting}
                possibleErrors={possibleUsernameErrors}
              />

              <InstantFeedbackField
                label="Password"
                name="password"
                type="password"
                placeholder="password"
                disabled={formik.isSubmitting}
                possibleErrors={possiblePasswordErrors}
                protectedField={true}
              />

              <NotificationBanner notificationInfo={status} />
              <div className="grid grid-cols-2 gap-4 mt-4 mb-3">
                <GrayButton
                  text="Cancel"
                  onClick={() => navigate('/')}
                  disabled={formik.isSubmitting}
                />
                <PrimaryButton
                  text="Sign up"
                  disabled={formik.isSubmitting}
                  type="submit"
                  status={formik.isSubmitting ? 'loading' : 'default'}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default Signup;
