import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from 'react-redux';
import { DrawerType } from './Drawer';

interface StateType {
  activeDrawer: DrawerType;
  selectedEventId?: string;
  showMenu: boolean;
  calendarLengthI: number;
}

const initialState: StateType = {
  activeDrawer: 'none',
  selectedEventId: undefined,
  showMenu: false,
  calendarLengthI: 1,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setActiveDrawer(state, action: PayloadAction<DrawerType>) {
      state.activeDrawer = action.payload;
    },
    setSelectedEventId(state, action: PayloadAction<string | undefined>) {
      state.selectedEventId = action.payload;
    },
    setShowMenu(state, action: PayloadAction<boolean>) {
      state.showMenu = action.payload;
    },
    setCalendarLengthI(state, action: PayloadAction<number>) {
      state.calendarLengthI = action.payload;
    },
    incrementCalendarLengthI(state, action: PayloadAction<number>) {
      // accepts number of cal lengths
      state.calendarLengthI = (state.calendarLengthI + 1) % action.payload;
    },
  },
});

export const uiReducer = uiSlice.reducer;

interface RootState {
  ui: StateType;
}

export const useUISelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const {
  setActiveDrawer,
  setSelectedEventId,
  setShowMenu,
  setCalendarLengthI,
  incrementCalendarLengthI,
} = uiSlice.actions;
