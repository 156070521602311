import * as Yup from 'yup';

export const possibleUsernameErrors = [
  'Between 3 and 30 characters long',
  'Only contains alphanumeric characters',
  'Contains no spaces',
];
export const possiblePasswordErrors = [
  'Between 6 and 50 characters long',
  'Only contains alphanumeric and special characters',
  'Contains no spaces',
];

export type MultipleFieldErrors = number[];

export const usernameSchema = Yup.object({
  username: Yup.string()
    .test('len', possibleUsernameErrors[0], (val) =>
      val !== undefined ? val.length >= 3 && val.length <= 30 : false,
    )
    .matches(/^[ A-Za-z0-9]*$/, possibleUsernameErrors[1])
    .matches(/^\S*$/, possibleUsernameErrors[2]),
});

export const passwordSchema = Yup.object({
  password: Yup.string()
    .test('len', possiblePasswordErrors[0], (val) =>
      val !== undefined ? val.length >= 6 && val.length <= 50 : false,
    )
    .matches(/^[ A-Za-z0-9_!@#$%^&*]*$/, possiblePasswordErrors[1])
    .matches(/^\S*$/, possiblePasswordErrors[2]),
});

const validateMultiple = async (
  schema: any,
  name: string,
  value: string,
  possibleErrors: string[],
) => {
  try {
    await schema.validate(
      {
        [name]: value,
      },
      { abortEarly: false },
    );
  } catch (e) {
    if (e instanceof Yup.ValidationError) {
      const errors = e.errors;

      return errors.map((s) => possibleErrors.indexOf(s));
    }
  }
  return undefined;
};

export const multivalueValidation = async (
  name: string,
  // @ts-ignore
  value,
  // @ts-ignore
  schema,
  possibleErrors: string[],
): Promise<MultipleFieldErrors | undefined> => {
  const valueErrors = await validateMultiple(
    schema,
    name,
    value,
    possibleErrors,
  );

  return valueErrors;
};

const signupValidationSchema = Yup.object({
  fullName: Yup.string()
    .required('Full name is required')
    .min(3, 'Minimum of 3 characters')
    .max(50, 'Maximum of 50 characters'),

  email: Yup.string()
    .required('Email is required')
    .email('Email address is invalid'),
});

export default signupValidationSchema;
