import { gql, useLazyQuery, useQuery } from '@apollo/client';
import {
  BASIC_CALENDAR_DETAILS,
  BASIC_EVENT_DETAILS,
  BASIC_LOCATION_DETAILS,
  BASIC_USER_DETAILS,
} from './fragments';

export type AccountState = 'USER' | 'VERIFY_EMAIL' | 'GUEST';

interface MeResultsBase {
  id: string;
  accountState: AccountState;
}

interface UserSettings {
  timeZone: string;
}

export interface MeUserResults extends MeResultsBase {
  username: string;
  fullName: string;
  email: string;
  accountState: 'USER';
  userSettings: UserSettings;
}

interface MeVerifyResults extends MeResultsBase {
  username: string;
  fullName: string;
  email: string;
  accountState: 'VERIFY_EMAIL';
}

interface MeGuestResults extends MeResultsBase {
  accountState: 'GUEST';
}

export type MeResults = MeUserResults | MeVerifyResults | MeGuestResults;

interface MeResultsNamed {
  me: MeResults;
}

export const ME = gql`
  query me {
    me {
      ...BasicUserDetails
    }
  }
  ${BASIC_USER_DETAILS}
`;

export const useMeQuery = () => {
  return useQuery<MeResultsNamed>(ME);
};

export interface BasicEventResults {
  id: string;
  title: string;
  startDate: number; // unix time
  endDate: number; // unix time
  names: string;

  location: {
    id: string;
    name: string;

    color: string;
  };

  calendar: {
    id: string;
    miniId: string;
  };

  color: string;
  owner: { id: string };

  __typename: 'Event';
}

export interface BasicLocationResults {
  id: string;
  name: string;
  location: string;
  address: string;
  description: string;

  popularity: 'LOW' | 'MEDIUM' | 'HIGH';
  color: string;
  // uploadedImageIds: string[];

  calendar: {
    id: string;
    title: string;
    owner: { id: string };
    miniId: string;
  };

  miniId: string;
  __typename: 'Location';
}

export type CalendarAccess = 'OWNER' | 'SPECIFIED_USERS' | 'LINK';

export interface BasicCalendarResults {
  id: string;
  title: string;
  description: string;
  specifiedUsers: { email: string }[];
  owner: { id: string, username: string };
  access: CalendarAccess;

  events: BasicEventResults[];
  locations: BasicLocationResults[];

  miniId: string;
  __typename: 'Calendar';
}

interface BasicCalendarResultsNamed {
  myCalendars: BasicCalendarResults[];
}

export const MY_CALENDARS = gql`
  query myCalendars {
    myCalendars {
      ...BasicCalendarDetails
    }
  }
  ${BASIC_CALENDAR_DETAILS}
`;

export const useMyCalendars = () => {
  return useQuery<BasicCalendarResultsNamed>(MY_CALENDARS);
};

export const useLazyMyCalendars = () => {
  return useLazyQuery<BasicCalendarResultsNamed>(MY_CALENDARS);
};

interface CalendarByMiniIdDetails {
  miniId: string;
}

interface CalendarByIdNamed {
  calendarByMiniId?: BasicCalendarResults;
}

const CALENDAR_BY_ID = gql`
  query calendarByMiniId($miniId: ID!) {
    calendarByMiniId(miniId: $miniId) {
      ...BasicCalendarDetails
    }
  }
  ${BASIC_CALENDAR_DETAILS}
`;

export const useCalendarById = (details: CalendarByMiniIdDetails) => {
  return useQuery<CalendarByIdNamed, CalendarByMiniIdDetails>(CALENDAR_BY_ID, {
    variables: details,
  });
};

export const useLazyCalendarById = () => {
  return useLazyQuery<CalendarByIdNamed, CalendarByMiniIdDetails>(
    CALENDAR_BY_ID,
  );
};

interface BasicLocationResultsNamed {
  calendarByMiniId: { locations: BasicLocationResults[] };
}

interface LocationsByCalendarIdDetails {
  miniId: string;
}

const LOCATIONS = gql`
  query calendarByMiniId($miniId: String!) {
    calendarByMiniId(miniId: $miniId) {
      locations {
        ...BasicLocationDetails
      }
    }
  }
  ${BASIC_LOCATION_DETAILS}
`;

export const useLocations = (details: LocationsByCalendarIdDetails) => {
  return useQuery<BasicLocationResultsNamed, LocationsByCalendarIdDetails>(
    LOCATIONS,
    {
      variables: details,
    },
  );
};

interface BasicLocationByIdResultsNamed {
  locationByMiniId?: BasicLocationResults;
}

interface LocationByIdDetails {
  locationMiniId: string;
  calendarMiniId: string;
}

const LOCATION_BY_ID = gql`
  query locationByMiniId($locationMiniId: ID!, $calendarMiniId: ID!) {
    locationByMiniId(
      locationMiniId: $locationMiniId
      calendarMiniId: $calendarMiniId
    ) {
      ...BasicLocationDetails
    }
  }
  ${BASIC_LOCATION_DETAILS}
`;

export const useLocationById = (details: LocationByIdDetails) => {
  return useQuery<BasicLocationByIdResultsNamed, LocationByIdDetails>(
    LOCATION_BY_ID,
    { variables: details },
  );
};

export const useLazyLocationById = () => {
  return useLazyQuery<BasicLocationByIdResultsNamed, LocationByIdDetails>(
    LOCATION_BY_ID,
  );
};

interface BasicEventByIdResultsNamed {
  eventById?: BasicEventResults;
}

interface EventByIdDetails {
  eventId: string;
}

const EVENT_BY_ID = gql`
  query eventById($eventId: ID!) {
    eventById(eventId: $eventId) {
      ...BasicEventDetails
    }
  }
  ${BASIC_EVENT_DETAILS}
`;

export const useLazyEventById = () => {
  return useLazyQuery<BasicEventByIdResultsNamed, EventByIdDetails>(
    EVENT_BY_ID,
  );
};
