import CalendarLocations from './CalendarLocations';
import CreateCalendar from './CreateCalendar';
import LocationPage from './LocationPage';
import CreateLocation from './CreateLocation';
import MyCalendars from './MyCalendars';
import Page from './Page';
import UserSettings from './UserSettings';

const UserSettingsPage = () => (
  <Page>
    <UserSettings />
  </Page>
);

const CreateCalendarPage = () => (
  <Page>
    <CreateCalendar mode="create" />
  </Page>
);

const CalendarSettingsPage = () => (
  <Page>
    <CreateCalendar mode="modify" />
  </Page>
);

const MyCalendarsPage = () => (
  <Page>
    <MyCalendars />
  </Page>
);

const CalendarLocationsPage = () => (
  <Page>
    <CalendarLocations />
  </Page>
);

const LocationPagePage = () => (
  <Page>
    <LocationPage />
  </Page>
);

const CreateLocationPage = () => (
  <Page>
    <CreateLocation mode="create" />
  </Page>
);

const LocationSettingsPage = () => (
  <Page>
    <CreateLocation mode="modify" />
  </Page>
);

export {
  UserSettingsPage,
  CreateCalendarPage,
  CalendarSettingsPage,
  MyCalendarsPage,
  CalendarLocationsPage,
  LocationPagePage,
  CreateLocationPage,
  LocationSettingsPage,
};
