import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useModifyUserMutation } from '../../graphql/mutations';
import { useMeQuery } from '../../graphql/queries';
import useStatus from '../../hooks/useStatus';
import {
  multivalueValidation,
  passwordSchema,
  possiblePasswordErrors,
} from '../../schemas/signupSchema';
import userSettingsValidationSchema from '../../schemas/userSettingsSchema';
import { onSubmitHandleError } from '../onSubmitHandleError';
import { GrayButton, PrimaryButton } from '../utilities/buttons';
import {
  InstantFeedbackField,
  NotificationBanner,
  ProtectedField,
  TextField,
} from '../utilities/formfields';

const UserSettings = () => {
  const [modifyUser] = useModifyUserMutation();

  const { data: userData, loading, error } = useMeQuery();

  const [modify, setModify] = useState(false);
  const { status, setNewStatus } = useStatus();

  if (loading) return <></>;
  if (error) return <h1>{error.message}</h1>;

  if (!userData || userData.me.accountState !== 'USER')
    return <h1>data undefined or user needs to verify account</h1>;

  let initialValues = {
    fullName: userData.me.fullName,
    timeZone: userData.me.userSettings.timeZone,
    oldPassword: '',
    password: '',
  };

  return (
    <div className="px-8">
      <h1 className="text-h1 text-xl font-bold text-center mt-2 mb-6">
        User settings
      </h1>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={async (values) => {
          if (values.oldPassword.length > 0) {
            const passwordErrors = await multivalueValidation(
              'password',
              values.password,
              passwordSchema,
              possiblePasswordErrors,
            );
            if (passwordErrors) {
              return {
                password: passwordErrors,
              };
            }
          }
        }}
        validationSchema={userSettingsValidationSchema}
        onSubmit={(values, submitProps) => {
          onSubmitHandleError<typeof values>(
            async () => {
              await modifyUser({
                variables: {
                  ...values,
                  oldPassword: values.oldPassword
                    ? values.oldPassword
                    : undefined,
                  password: values.password ? values.password : undefined,
                },
              });

              setModify(false);
              submitProps.resetForm();
            },
            setNewStatus,
            submitProps,
          );
        }}
      >
        {(formik) => {
          useEffect(() => {
            formik.validateForm();
          }, []);

          return (
            <Form>
              <p className="text-body2 text-sm text-left my-2">Username</p>
              <p className="pb-2">
                {userData.me.accountState === 'USER' && userData.me.username}
              </p>

              <p className="text-body2 text-sm text-left my-2">Email</p>
              <p className="pb-2">
                {userData.me.accountState === 'USER' && userData.me.email}
              </p>

              <TextField
                label="Full name"
                name="fullName"
                type="text"
                placeholder="First Last"
                disabled={!modify || formik.isSubmitting}
              />

              <TextField
                label="Time zone"
                name="timeZone"
                type="text"
                placeholder="America/Chicago"
                disabled={!modify || formik.isSubmitting}
              />

              <h2 className="text-body2 font-bold mt-3">Change password</h2>

              <ProtectedField
                label="Old password"
                name="oldPassword"
                type="password"
                placeholder="old password"
                disabled={!modify || formik.isSubmitting}
              />

              <InstantFeedbackField
                label="New password"
                name="password"
                type="password"
                placeholder="new password"
                disabled={
                  formik.values.oldPassword.length === 0 ||
                  !modify ||
                  formik.isSubmitting
                }
                possibleErrors={possiblePasswordErrors}
                activated={formik.values.oldPassword.length > 0}
              />
              <NotificationBanner notificationInfo={status} />
              <div className="grid grid-cols-2 gap-4 pt-8 pb-3">
                {modify ? (
                  <>
                    <GrayButton
                      text="Cancel"
                      disabled={formik.isSubmitting}
                      onClick={() => {
                        setModify(false);
                        formik.resetForm();
                      }}
                    />
                    <PrimaryButton
                      text="Save"
                      disabled={
                        formik.isSubmitting ||
                        (formik.initialValues.fullName ===
                          formik.values.fullName &&
                          formik.initialValues.timeZone ===
                            formik.values.timeZone &&
                          formik.initialValues.password ===
                            formik.values.password)
                      }
                      type="submit"
                      status={formik.isSubmitting ? 'loading' : 'default'}
                    />
                  </>
                ) : (
                  <PrimaryButton
                    text="Modify"
                    customButtonClasses={'col-span-2'}
                    type="button"
                    onClick={() => setModify(true)}
                  />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default UserSettings;
