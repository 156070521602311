import { configureStore, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from 'react-redux';
import { uiReducer } from '../Calendar/CalendarRedux';

interface StateType {
  toastMessage: string;
  timeoutId?: NodeJS.Timeout;
}

const initialState: StateType = {
  toastMessage: '',
  timeoutId: undefined,
};

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    setToastMessage(state, action: PayloadAction<string>) {
      state.toastMessage = action.payload;
    },
    clearTimeout(state) {
      clearInterval(state.timeoutId);
      state.timeoutId = undefined;
    },
    setTimeoutId(state, action: PayloadAction<NodeJS.Timeout>) {
      state.timeoutId = action.payload;
    },
  },
});

const toastReducer = toastSlice.reducer;
const reduxStore = configureStore({
  reducer: {
    toast: toastReducer,
    ui: uiReducer,
  },
});

interface RootState {
  toast: StateType;
}

export const useToastSelector: TypedUseSelectorHook<RootState> =
  useReduxSelector;

const { setToastMessage, clearTimeout, setTimeoutId } = toastSlice.actions;
const setToast = async (message: string) => {
  reduxStore.dispatch(clearTimeout());
  reduxStore.dispatch(setToastMessage(message));

  const timeoutId = setTimeout(
    () => reduxStore.dispatch(setToastMessage('')),
    Math.min(Math.max(message.length * 50, 2000), 7000),
  );
  reduxStore.dispatch(setTimeoutId(timeoutId));
};
export default reduxStore;
export { setToast };
