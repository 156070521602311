import { SetStateAction, useEffect, useState } from 'react';
import { ChevronLeft, FilledPlusIcon } from '../../Icons';
import Header from '../Header';
import CreateEvent from './CreateEvent';
import Drawer from './Drawer';
import EventDetails from './EventDetails';
import Filter, { FilterInfo } from './Filter';
import {
  EventComponentInfo,
  getHour,
  getDay,
  dateToFormat,
  eventsToEventComponentInfos,
  EventComponentInfos,
} from './utilities';
import {
  setActiveDrawer,
  setSelectedEventId,
  useUISelector,
} from './CalendarRedux';
import { useDispatch } from 'react-redux';
import {
  BasicEventResults,
  BasicLocationResults,
  useCalendarById,
  useMeQuery,
} from '../../graphql/queries';
import { useParams } from 'react-router-dom';

export type CalendarBackground = 'blur' | 'darken' | 'none';

interface CalendarProps {
  headerType: 'calendar' | 'create account' | 'secondary';
  background?: CalendarBackground;
}

interface HourLabelProps {
  hour?: string;
}

interface HourComponentProps {
  isCurrentDay: boolean;
  events?: [EventComponentInfo];
}

type CalendarLength = '1d' | '4d' | '1w';
export const calendarLengths: CalendarLength[] = ['1d', '4d', '1w'];

const HourLabel = ({ hour }: HourLabelProps) => (
  <div className="relative">
    <div className="absolute top-7 right-3 text-sm font-light text-body whitespace-nowrap">
      {hour}
    </div>
  </div>
);

const EventComponent = ({ e }: { e: EventComponentInfo }) => {
  const dispatch = useDispatch();
  const { selectedEventId } = useUISelector((state) => state.ui);

  return (
    <button
      onClick={() => {
        dispatch(setActiveDrawer('none'));

        if (selectedEventId && selectedEventId === e.eventId) {
          dispatch(setSelectedEventId(undefined));
        } else {
          dispatch(setSelectedEventId(e.eventId));
        }
      }}
    >
      <div
        className={`absolute z-20 w-full bg-opacity-90 ${
          e.startsOnThisDay ? 'rounded-t-lg' : ''
        } ${e.endsOnThisDay ? 'rounded-b-lg' : ''}`}
        style={{
          top: e.top,
          height: e.height,
          left: e.left,
          width: e.width,
          backgroundColor: e.color,
        }}
      >
        <p className="text-white text-xs text-center break-all p-1 mt-2">
          {e.title}
        </p>
      </div>
    </button>
  );
};

const HourComponent = ({ isCurrentDay, events }: HourComponentProps) => {
  return (
    <div className={`border-[1px] relative ${isCurrentDay && 'bg-teal-50'}`}>
      {events?.map((e) => (
        <EventComponent e={e} key={e.eventId} />
      ))}
    </div>
  );
};

const getDayGrid = (
  numDays: number,
  startDate: Date,
  setStartDate: React.Dispatch<SetStateAction<Date>>,
) => {
  const days = Array(numDays)
    .fill(0)
    .map((_, i) => getDay(i, startDate, numDays === 7 ? true : false));

  let currentDayI = -1;
  const dayGrid = Array(numDays)
    .fill(0)
    .map((_, i) => {
      const { dayOfWeek, date, fullDate } = days[i];
      const currentDay = fullDate === dateToFormat(new Date());
      if (currentDay) currentDayI = i;

      return (
        <div
          className={`sticky self-start top-0 left-0
                    bg-pureWhite z-30 h-full ${currentDay && 'bg-teal-50'}`}
          key={i}
        >
          <p className="text-sm font-light text-center text-body ">
            {dayOfWeek}
          </p>
          <p className="text-sm font-light text-center text-body ">{date}</p>
        </div>
      );
    });

  dayGrid.unshift(
    <div
      className={`sticky self-start top-0 left-0
                bg-pureWhite z-30 h-full flex items-center justify-center`}
      key={-1}
    >
      <button
        onClick={() => {
          setStartDate((prev) => {
            const date = new Date(prev);
            date.setDate(date.getDate() - numDays);
            return date;
          });
        }}
      >
        <ChevronLeft />
      </button>
      <button
        onClick={() => {
          setStartDate((prev) => {
            const date = new Date(prev);
            date.setDate(date.getDate() + numDays);
            return date;
          });
        }}
      >
        <div className="rotate-180">
          <ChevronLeft />
        </div>
      </button>
    </div>,
  );

  return { currentDayI, dayGrid };
};

const getGrid = (
  numDays: number,
  days: { dateObject: string | number | Date }[],
  currentDayI: number,
  eventComponentInfos: EventComponentInfos,
) => {
  return (
    Array((numDays + 1) * 24)
      .fill(0)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map((_, i) => {
        if (i % (numDays + 1) === 0) {
          // hours on left-hand side
          return <HourLabel hour={getHour(i / (numDays + 1))} key={i} />;
        }

        // hour grid with events
        if (i < numDays + 1) {
          const currDate = new Date(days[i - 1].dateObject);
          currDate.setHours(0, 0, 0, 0);

          return (
            <HourComponent
              isCurrentDay={(i % (numDays + 1)) - 1 === currentDayI}
              events={eventComponentInfos[currDate.getTime()]}
              key={i}
            />
          );
        }

        return (
          <HourComponent
            isCurrentDay={(i % (numDays + 1)) - 1 === currentDayI}
            key={i}
          />
        );
      })
  );
};

const CalendarComponent = ({
  numDays,
  allEvents,
  allLocations,
}: {
  numDays: number;
  allEvents: BasicEventResults[];
  allLocations: BasicLocationResults[];
}) => {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const dispatch = useDispatch();

  useEffect(() => {
    document.getElementById('grid')?.scrollTo({ top: 40 * 5 - 20 });
  }, []);

  const days = Array(numDays)
    .fill(0)
    .map((_, i) => getDay(i, startDate, numDays === 7 ? true : false));

  const { currentDayI, dayGrid } = getDayGrid(numDays, startDate, setStartDate);

  const eventComponentInfos = eventsToEventComponentInfos(
    allEvents,
    allLocations,
  );

  const grid = getGrid(numDays, days, currentDayI, eventComponentInfos);

  const gridCols = '60px' + ' 1fr'.repeat(numDays);
  return (
    <div className="flex">
      <div
        className={`grid grid-rows-[repeat(25,_40px)] 
                      overflow-auto h-[calc(100vh-3.5rem)] flex-grow`}
        id="grid"
        style={{ gridTemplateColumns: gridCols }}
      >
        {dayGrid}
        {grid}
      </div>
      <div className="fixed bottom-4 right-4 z-20">
        <button onClick={() => dispatch(setActiveDrawer('create event'))}>
          <FilledPlusIcon />
        </button>
      </div>
    </div>
  );
};

const CalendarComponentWithData = ({
  numDays,
  filter,
}: {
  numDays: number;
  filter: FilterInfo;
}) => {
  const selectedCalendarId = useParams().calendarId;
  if (!selectedCalendarId) return <h1>no id in url</h1>;

  const {
    data: calendarsData,
    loading,
    error,
  } = useCalendarById({ miniId: selectedCalendarId });

  if (loading) return <></>;
  if (!calendarsData || error) return <h1>Data couldn't be loaded</h1>;

  const currCalendar = calendarsData.calendarByMiniId;

  if (!currCalendar) return <h1>Calendar couldn't be found</h1>;

  let allEvents = currCalendar.events;

  if (filter.search) {
    allEvents = allEvents.filter((e) =>
      e.title.toLowerCase().includes(filter.search.toLowerCase()),
    );
  }

  let allLocations = currCalendar.locations;
  allLocations = allLocations.filter(
    (_, i) => !filter.deselectedLocationIs.includes(i),
  );
  allEvents = allEvents.filter((e) =>
    allLocations.map((l) => l.id).includes(e.location.id),
  );

  return (
    <CalendarComponent
      numDays={numDays}
      allEvents={allEvents}
      allLocations={allLocations}
    />
  );
};

const UnsavedChanges = () => {
  const guestCreatedAt = parseInt(
    localStorage.getItem('guestCreatedAt') || '0',
  );

  return (
    <div className="fixed bottom-[10%] z-50 bg-gray-100 rounded-md w-4/5 max-w-md drop-shadow-lg py-2 px-2 left-1/2 -translate-x-1/2">
      <p className="text-body text-center">
        Your changes are unsaved. Create an account to save your work within{' '}
        {Math.ceil(24 - (new Date().getTime() - guestCreatedAt) / (1000 * 60))}{' '}
        hours
      </p>
    </div>
  );
};

const Calendar = ({ headerType, background = 'none' }: CalendarProps) => {
  const { calendarLengthI } = useUISelector((state) => state.ui);

  let numDays = 0;
  const calendarLength = calendarLengths[calendarLengthI];
  if (calendarLength === '1d') {
    numDays = 1;
  } else if (calendarLength === '4d') {
    numDays = 4;
  } else if (calendarLength === '1w') {
    numDays = 7;
  }

  if (headerType === 'create account') {
    return (
      <div
        className={'h-screen ' + (background === 'blur' ? 'blur-[2px]' : '')}
      >
        <Header headerType={headerType} owner=" " />
        <CalendarComponent numDays={numDays} allEvents={[]} allLocations={[]} />
      </div>
    );
  }
  const selectedCalendarId = useParams().calendarId;
  if (!selectedCalendarId) return <h1>no id in url</h1>;

  const { data: userData, loading: userLoading } = useMeQuery();

  const {
    data: calendarData,
    loading,
    error,
  } = useCalendarById({ miniId: selectedCalendarId });

  const [filter, setFilter] = useState<FilterInfo>({
    search: '',
    deselectedLocationIs: [],
  });

  const { activeDrawer, selectedEventId } = useUISelector((state) => state.ui);

  if (loading || userLoading) return <></>;
  if (!calendarData || error) return <h1>Data couldn't be loaded</h1>;

  const currCalendar = calendarData.calendarByMiniId;
  if (!currCalendar) return <h1>Calendar couldn't be found</h1>;

  let drawer;
  if (activeDrawer === 'filter') {
    drawer = <Filter filterInfo={filter} setFilterInfo={setFilter} />;
  } else if (activeDrawer === 'create event') {
    drawer = (
      <CreateEvent mode="create" selectedCalendarId={selectedCalendarId} />
    );
  } else if (activeDrawer === 'modify event') {
    drawer = (
      <CreateEvent mode="modify" selectedCalendarId={selectedCalendarId} />
    );
  } else if (selectedEventId) {
    const selectedEvent = currCalendar.events.find(
      (c) => c.id === selectedEventId,
    );
    if (selectedEvent) {
      drawer = <EventDetails event={selectedEvent} />;
    }
  }

  const isGuest = userData && userData.me.accountState === 'GUEST';
  if (isGuest) {
    headerType = 'create account';
  }

  return (
    <div className={'h-screen ' + (background === 'blur' ? 'blur-[2px]' : '')}>
      <Header headerType={headerType} owner={currCalendar.owner.id} />
      <CalendarComponentWithData numDays={numDays} filter={filter} />
      {isGuest && <UnsavedChanges />}
      {drawer && <Drawer>{drawer}</Drawer>}
    </div>
  );
};

export default Calendar;
