import { useDispatch } from 'react-redux';
import { BasicEventResults, useMeQuery } from '../../graphql/queries';
import {
  GrayButton,
  LocationButton,
  PrimaryButton,
} from '../utilities/buttons';
import { setActiveDrawer, setSelectedEventId } from './CalendarRedux';
import { sameDay } from './utilities';

const EventDetails = ({ event }: { event: BasicEventResults }) => {
  const { data: userData, loading } = useMeQuery();
  const dispatch = useDispatch();

  if (loading) return <></>;
  if (userData && userData.me.accountState === 'VERIFY_EMAIL')
    return <h1>user needs to verify account</h1>;

  const { startDate: startDateUnix, endDate: endDateUnix } = event;
  const startDate = new Date(startDateUnix);
  const endDate = new Date(endDateUnix);
  const isOwner = userData ? event.owner.id === userData.me.id : false;

  let dateDisplay;
  const fullOptions: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  if (sameDay(startDate, endDate)) {
    dateDisplay = startDate.toLocaleDateString(undefined, fullOptions);
    dateDisplay +=
      ' - ' +
      endDate.toLocaleTimeString(undefined, {
        hour: 'numeric',
        minute: 'numeric',
      });
  } else {
    dateDisplay = startDate.toLocaleDateString(undefined, fullOptions);
    dateDisplay += ' - ' + endDate.toLocaleDateString(undefined, fullOptions);
  }

  const location = event.location;
  
  return (
    <div>
      <h1 className="text-center text-lg font-bold">{event.title}</h1>
      <div className="mt-8">
        <span className="text-body">Date: </span>
        <span className="font-light text-body2">{dateDisplay}</span>
      </div>
      <div className="mt-4">
        <span className="text-body">Name(s): </span>
        <span className="font-light text-body2">{event.names}</span>
      </div>
      <div className="mt-4">
        {<LocationButton text={location.name} color={location.color} />}
      </div>
      <div className="grid grid-cols-2 gap-12 mt-8 mb-2">
        <GrayButton
          text="Back"
          customButtonClasses={isOwner ? '' : 'col-span-2'}
          onClick={() => dispatch(setSelectedEventId(undefined))}
        />
        <PrimaryButton
          text="Modify"
          customButtonClasses={isOwner ? 'block' : 'hidden'}
          onClick={() => dispatch(setActiveDrawer('modify event'))}
        />
      </div>
    </div>
  );
};

export default EventDetails;
