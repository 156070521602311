import { ApolloError } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation,
  useParams,
} from 'react-router-dom';
import Calendar from './app/Calendar';
import { setCalendarLengthI } from './app/Calendar/CalendarRedux';
import {
  CalendarLocationsPage,
  CalendarSettingsPage,
  LocationPagePage,
  CreateLocationPage,
  MyCalendarsPage,
  UserSettingsPage,
  CreateCalendarPage,
  LocationSettingsPage,
} from './app/Pages';
import Toast from './app/Toast/Toast';
import { LoginModal, SignupModal, WelcomeModal } from './app/WelcomeModals';
import { useCalendarById, useMeQuery } from './graphql/queries';

interface AuthRouteProps {
  nonauthRedirectPath?: string;
  nonauthChildren?: JSX.Element;
  children: JSX.Element;
  bypassWhenGuest?: boolean;
}

const AuthRoute = ({
  nonauthRedirectPath,
  nonauthChildren,
  children, // children if authenticated
  bypassWhenGuest = false,
}: AuthRouteProps) => {
  // check if authenticated
  const location = useLocation();
  const { data: userData, loading, error, refetch } = useMeQuery();
  useEffect(() => {
    refetch();
  }, [localStorage.getItem('sessionId'), sessionStorage.getItem('sessionId')]);

  if (loading) return <></>;
  if (error) {
    if (error instanceof ApolloError) {
      if (
        error.graphQLErrors.find(
          (e) => e.extensions?.code === 'UNAUTHENTICATED',
        )
      ) {
        if (nonauthRedirectPath) {
          return (
            <Navigate
              to={nonauthRedirectPath}
              state={{ from: location }}
              replace
            />
          );
        } else if (nonauthChildren) {
          return nonauthChildren;
        }
      }
    }

    if (error instanceof Error) {
      return <h1>Server error: {error.message}</h1>;
    }
  }

  if (userData) {
    if (
      userData.me.accountState === 'GUEST' &&
      bypassWhenGuest &&
      nonauthChildren
    ) {
      return nonauthChildren;
    }

    if (userData.me.accountState === 'VERIFY_EMAIL') {
      return (
        <Navigate
          to="/signup"
          state={{ from: location, email: userData.me.email }}
          replace
        />
      );
    } else {
      return children;
    }
  }
  return <></>;
};

const CalendarAuthRoute = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const selectedCalendarId = useParams().calendarId;
  if (!selectedCalendarId) return <h1>no id in url</h1>;

  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useMeQuery();
  const {
    data: calendarData,
    loading,
    error,
  } = useCalendarById({ miniId: selectedCalendarId });

  if (loading || userLoading) return <></>;

  const hasAccess = calendarData?.calendarByMiniId && !error;

  if (hasAccess) {
    return children;
  }

  const loggedin = userData && !userError;
  if (!loggedin) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else {
    return <Navigate to="/unauthorized" replace />;
  }
};

const AllRoutes = () => (
  <Routes>
    {/* redirect to home calendar (/id) if logged in, else display homepage  */}

    <Route
      path="/"
      element={
        <AuthRoute
          nonauthChildren={
            <>
              <Calendar headerType="create account" background="blur" />
              <WelcomeModal />
            </>
          }
        >
          <MyCalendarsPage />
        </AuthRoute>
      }
    />

    <Route
      path="/"
      element={
        <AuthRoute
          nonauthChildren={
            <div>
              <Calendar headerType="create account" background="blur" />
              <Outlet />
            </div>
          }
          bypassWhenGuest={true}
        >
          <Navigate to="/" replace />
        </AuthRoute>
      }
    >
      <Route path="/login" element={<LoginModal />} />
      <Route path="/signup" element={<SignupModal />} />
    </Route>

    <Route
      path="/newcalendar"
      element={
        <AuthRoute nonauthRedirectPath="/login">
          <CreateCalendarPage />
        </AuthRoute>
      }
    />

    <Route
      path="/account"
      element={
        <AuthRoute nonauthRedirectPath="/login">
          <UserSettingsPage />
        </AuthRoute>
      }
    />

    <Route path="/unauthorized" element={<h1>Unauthorized access</h1>} />

    <Route
      path="/:calendarId"
      element={
        <CalendarAuthRoute>
          <Outlet />
        </CalendarAuthRoute>
      }
    >
      <Route path="" element={<Calendar headerType="calendar" />} />
      <Route path="settings" element={<CalendarSettingsPage />} />

      <Route
        path="locations"
        element={
          <div>
            <Outlet />
          </div>
        }
      >
        <Route path="" element={<CalendarLocationsPage />} />
        <Route path="newlocation" element={<CreateLocationPage />} />
        <Route path=":locationId" element={<LocationPagePage />} />
        <Route path=":locationId/settings" element={<LocationSettingsPage />} />
      </Route>
    </Route>

    <Route path="*" element={<h1>404</h1>} />
  </Routes>
);

const App = () => {
  // set sensible default calendar length
  const dispatch = useDispatch();
  if (window.innerWidth < 240) {
    dispatch(setCalendarLengthI(0));
  } else if (window.innerWidth < 768) {
    // lg
    dispatch(setCalendarLengthI(1));
  } else {
    dispatch(setCalendarLengthI(2));
  }

  return (
    <div className="relative">
      <AllRoutes />
      <Toast />
    </div>
  );
};

export default App;
