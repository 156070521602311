import { useState } from 'react';

export interface NotificationInfo {
  type: 'success' | 'failure';
  text?: string;
}

const useStatus = () => {
  const [status, setStatus] = useState<NotificationInfo | undefined>();
  const [currTimeout, setCurrTimeout] = useState<NodeJS.Timeout>();

  const resetStatus = () => {
    clearTimeout(currTimeout);
    setStatus(undefined);
  };

  const setNewStatus = (newStatus: NotificationInfo, timeout?: number) => {
    clearTimeout(currTimeout);
    setStatus(newStatus);
    if (timeout) {
      setCurrTimeout(setTimeout(resetStatus, timeout));
    }
  };

  return { status, setNewStatus, resetStatus };
};

export default useStatus;
