import { Form, Formik, yupToFormErrors } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { cache } from '../..';
import {
  useCreateLocationMutation,
  useDeleteLocationMutation,
  useModifyLocationMutation,
} from '../../graphql/mutations';
import { useCalendarById, useLazyLocationById } from '../../graphql/queries';
import useStatus from '../../hooks/useStatus';
import { locationClientValidationSchema } from '../../schemas/locationSchema';
import { onSubmitHandleError } from '../onSubmitHandleError';
import { DangerButton, GrayButton, PrimaryButton } from '../utilities/buttons';
import {
  MyTextarea,
  NotificationBanner,
  TextField,
} from '../utilities/formfields';

const createInitialValues = {
  name: '',
  location: '',
  address: '',
  description: '',
};

const CreateLocation = ({ mode }: { mode: 'create' | 'modify' }) => {
  const [getLocation, { data: locationData, loading: locationLoading }] =
    useLazyLocationById();

  const selectedCalendarId = useParams().calendarId;
  if (!selectedCalendarId) return <h1>No calendar id</h1>;
  const selectedLocationId = useParams().locationId;

  const {
    data: calendarData,
    loading: calendarLoading,
    error: calendarError,
  } = useCalendarById({ miniId: selectedCalendarId });

  const [createLocation] = useCreateLocationMutation();
  const [modifyLocation] = useModifyLocationMutation();
  const [deleteLocation] = useDeleteLocationMutation();
  const navigate = useNavigate();
  const { status, setNewStatus } = useStatus();

  if (locationLoading || calendarLoading) return <></>;
  if (calendarError) return <h1>Data couldn't be loaded</h1>;

  if (mode === 'modify' && selectedLocationId && !locationData) {
    getLocation({
      variables: {
        locationMiniId: selectedLocationId,
        calendarMiniId: selectedCalendarId,
      },
    });
  }

  let initialValues = createInitialValues;
  let locationNames = calendarData?.calendarByMiniId?.locations.map(
    (c) => c.name,
  );
  if (mode === 'modify') {
    let initialLocation = locationData?.locationByMiniId;
    if (!initialLocation) return <></>;

    initialValues = {
      name: initialLocation.name,
      location: initialLocation.location,
      address: initialLocation.address,
      description: initialLocation.description,
    };
    locationNames = locationNames?.filter((n) => n !== initialLocation!.name);
  }

  return (
    <div className="px-8">
      <h1 className="text-h1 text-xl font-bold text-center mt-2 mb-2">
        {mode === 'create' ? 'Create a location' : 'Location settings'}
      </h1>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          try {
            locationClientValidationSchema.validateSync(values, {
              abortEarly: false,
              context: {
                locationNames,
              },
            });
          } catch (err) {
            return yupToFormErrors(err);
          }
          return {};
        }}
        onSubmit={async (values, submitProps) => {
          onSubmitHandleError<typeof values>(
            async () => {
              let newLocationId;
              if (mode === 'create') {
                const result = await createLocation({
                  variables: {
                    name: values.name,
                    location: values.location,
                    address: values.address,
                    description: values.description,
                    color: '#86198F',
                    calendarMiniId: selectedCalendarId,
                  },
                });
                newLocationId = result.data?.createLocation.id;
              } else if (selectedLocationId) {
                await modifyLocation({
                  variables: {
                    name: values.name,
                    location: values.location,
                    address: values.address,
                    description: values.description,
                    color: '#86198F',

                    calendarMiniId: selectedCalendarId,
                    locationMiniId: selectedLocationId,
                  },
                });
              }

              if (mode === 'create' && !newLocationId) {
                setNewStatus(
                  {
                    type: 'failure',
                    text: `Server couldn't ${mode} location`,
                  },
                  5000,
                );
              } else {
                navigate(`/${selectedCalendarId}/locations`);
              }
            },
            setNewStatus,
            submitProps,
          );
        }}
      >
        {(formik) => (
          <Form className="pb-4">
            <TextField
              label="Name"
              name="name"
              type="text"
              placeholder="Room 1"
              disabled={formik.isSubmitting}
            />
            <TextField
              label="Location"
              name="location"
              type="text"
              placeholder="Room 132 on floor 1"
              disabled={formik.isSubmitting}
            />
            <TextField
              label="Address"
              name="address"
              type="text"
              placeholder="1600 Pennsylvania Ave NW, Washington DC"
              disabled={formik.isSubmitting}
            />
            <MyTextarea
              label="Description"
              name="description"
              placeholder="A short description of the location"
              disabled={formik.isSubmitting}
            />
            <NotificationBanner notificationInfo={status} />

            {mode === 'modify' && (
              <DangerButton
                text="Delete"
                customButtonClasses="px-8 block mx-auto mt-2"
                type="button"
                onClick={async () => {
                  onSubmitHandleError<typeof formik.values>(async () => {
                    if (selectedLocationId) {
                      const res = await deleteLocation({
                        variables: {
                          locationMiniId: selectedLocationId,
                          calendarMiniId: selectedCalendarId,
                        },
                      });

                      if (res.data) {
                        cache.evict({
                          id: `Location:${selectedLocationId}`,
                          broadcast: false,
                        });
                        cache.gc();
                        navigate('../');
                      }
                    }
                  }, setNewStatus);
                }}
              />
            )}
            <div className="grid grid-cols-2 gap-4 mt-4 mb-3">
              <GrayButton
                text="Back"
                onClick={() => navigate('../')}
                disabled={formik.isSubmitting}
              />
              <PrimaryButton
                text="Save"
                disabled={formik.isSubmitting}
                type="submit"
                status={formik.isSubmitting ? 'loading' : 'default'}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateLocation;
