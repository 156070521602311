import { CheckmarkIcon } from '../../Icons';

interface LocationButtonProps {
  text: string;
  color: string;
  pressed?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

interface SharingButtonProps {
  text: string;
  pressed?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export type TextButtonStatus = 'default' | 'loading' | 'success';

interface TextButtonProps {
  text?: string;
  type?: 'button' | 'submit' | 'reset';
  status?: TextButtonStatus;
  disabled?: boolean;
  defaultIcon?: JSX.Element;
  customTextClasses?: String;
  customButtonClasses?: String;
  customButtonStyle?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const TextButton = ({
  text,
  type,
  defaultIcon,
  status = 'default',
  customTextClasses = '',
  customButtonClasses = '',
  customButtonStyle = {},
  disabled = false,
  onClick,
}: TextButtonProps) => {
  let icon = <></>;
  switch (status) {
    case 'default':
      if (defaultIcon) {
        icon = <span className="w-4 h-4 absolute right-2">{defaultIcon}</span>;
      }
      break;
    case 'loading':
      icon = (
        <span className="animate-spin w-4 h-4 absolute right-2 border-[3px] border-pureWhite border-b-purple-800 rounded-full inline-block ml-2"></span>
      );
      break;
    case 'success':
      icon = (
        <span className="w-6 h-6 absolute right-1">
          {<CheckmarkIcon strokeColor="stroke-white" h="24" w="24" />}
        </span>
      );
  }

  return (
    <button
      className={
        'px-2 py-1 rounded-md max-w-xs w-full place-self-center ' +
        (status === 'loading' || defaultIcon
          ? 'flex items-center justify-center relative '
          : '') +
        (status === 'loading' ? '!bg-purple-700 ' : ' ') +
        (disabled ? '!bg-[rgb(156,163,175)] ' : ' ') +
        customButtonClasses
      }
      onClick={onClick}
      type={type ? type : 'button'}
      disabled={disabled}
      style={customButtonStyle}
    >
      <p className={'text-base text-white ' + customTextClasses}>{text}</p>
      {icon}
    </button>
  );
};

const PrimaryButton = (props: TextButtonProps) => (
  <TextButton
    {...props}
    customButtonClasses={
      'bg-buttonPrimary ' +
      (props.customButtonClasses ? props.customButtonClasses : '')
    }
  />
);

const SecondaryButton = (props: TextButtonProps) => (
  <TextButton
    {...props}
    customButtonClasses={
      'bg-buttonSecondary ' +
      (props.customButtonClasses ? props.customButtonClasses : '')
    }
  />
);

const DangerButton = (props: TextButtonProps) => (
  <TextButton
    {...props}
    customButtonClasses={
      'bg-buttonDanger ' +
      (props.customButtonClasses ? props.customButtonClasses : '')
    }
  />
);

const GrayButton = (props: TextButtonProps) => (
  <TextButton
    {...props}
    customButtonClasses={
      'bg-pureWhite ring-1 ring-gray-300 ' + props.customButtonClasses
    }
    customTextClasses={'!text-body2 ' + props.customTextClasses}
  />
);

const LocationButton = ({
  text,
  color,
  pressed = false,
  disabled = false,
  onClick,
}: LocationButtonProps) => (
  <button
    className="text-white rounded-full py-1.5 px-6 text-sm"
    style={{ backgroundColor: pressed ? '#9CA3AF' : color }}
    onClick={onClick}
    type="button"
    disabled={disabled}
  >
    {text}
  </button>
);

const SharingButton = ({
  text,
  pressed = false,
  disabled = false,
  onClick,
}: SharingButtonProps) => (
  <button
    className="text-white rounded-2xl py-2 px-2 text-sm"
    style={{ backgroundColor: pressed ? '#9CA3AF' : '#14B8A6' }} // gray-400, teal-500
    onClick={onClick}
    type="button"
    disabled={disabled}
  >
    {text}
  </button>
);

export {
  TextButton,
  PrimaryButton,
  SecondaryButton,
  DangerButton,
  GrayButton,
  LocationButton,
  SharingButton,
};
