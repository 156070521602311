import { NavigateFunction, useNavigate } from 'react-router-dom';
import {
  BasicCalendarResults,
  useMeQuery,
  useMyCalendars,
} from '../../graphql/queries';
import { CogIcon } from '../../Icons';
import { PrimaryButton, TextButton } from '../utilities/buttons';

const CalendarCard = ({
  calendarData,
  navigate,
  owner,
}: {
  calendarData: BasicCalendarResults;
  navigate: NavigateFunction;
  owner: boolean;
}) => (
  <div
    className="relative border-2 border-teal-800 flex flex-col items-center h-36 w-28 rounded-md p-2 
                  md:h-64 md:w-48"
  >
    <h2
      className="text-sm text-center text-teal-800 max-w-[6rem] break-words text-ellipsis line-clamp-2
                   md:text-lg md:max-w-[10rem]"
    >
      {calendarData.title}
    </h2>
    <div className='hidden md:block'>
      <p className="font-light text-body max-w-full break-words text-ellipsis text-center line-clamp-3 mt-2">
        {calendarData.description}
      </p>
    </div>
    <p
      className="text-body max-w-full overflow-hidden text-ellipsis text-center absolute bottom-12 line-clamp-2
                   text-sm md:text-base"
    >
      <span className="hidden md:block">Owned by </span>
      {calendarData.owner.username || 'you'}
    </p>
    {owner && (
      <button
        className="absolute right-1 top-1"
        onClick={() =>
          navigate(`/${calendarData.miniId}/settings`, { state: { from: '/' } })
        }
      >
        <CogIcon />
      </button>
    )}
    <TextButton
      text="Go"
      customButtonClasses="bg-teal-800 w-4/5 absolute bottom-2"
      onClick={() => navigate(`/${calendarData.miniId}`)}
    />
  </div>
);

const GuestNoNewCalendar = () => (
  <div className=" bg-gray-100 rounded-md w-4/5 max-w-md drop-shadow-lg py-2 px-2">
    <p className="text-body text-center">
      To create a new calendar, sign up for an account
    </p>
  </div>
);

const MyCalendars = () => {
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useMeQuery();
  const { data: calendarsData, loading, error } = useMyCalendars();
  const navigate = useNavigate();

  if (loading || userLoading) return <></>;
  if (!userData || !calendarsData || error || userError)
    return <h1>Data couldn't be loaded</h1>;

  const isGuest = userData.me.accountState === 'GUEST';

  return (
    <div className="flex flex-col items-center justify-between">
      <div className="pb-6">
        <h1 className="text-h1 text-xl font-bold text-center mt-2 mb-6">
          My calendars
        </h1>
        <div className="flex flex-wrap justify-center gap-8 px-8">
          {calendarsData.myCalendars.map((c) => (
            <CalendarCard
              calendarData={c}
              navigate={navigate}
              owner={c.owner.id === userData.me.id}
              key={c.id}
            />
          ))}
        </div>
      </div>
      <PrimaryButton
        text="New calendar"
        customButtonClasses="w-48 mb-8"
        onClick={() => navigate('/newcalendar')}
        disabled={isGuest}
      />
      {isGuest && <GuestNoNewCalendar />}
    </div>
  );
};

export default MyCalendars;
