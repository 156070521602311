import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Login from './Login';
import Signup from './Signup';
import VerifyEmail from './VerifyEmail';
import Welcome from './Welcome';

const Modal = ({ children }: { children: JSX.Element }) => (
  <div className="flex items-center justify-center fixed z-10 left-0 top-0 w-full h-full overflow-auto bg-[rgba(0,0,0,0.4)]">
    <div className="max-w-4xl flex flex-col items-center px-6 py-3 bg-pureWhite rounded-3xl w-10/12 text-center max-h-[90%] overflow-auto">
      {children}
    </div>
  </div>
);

const WelcomeModal = () => (
  <Modal>
    <Welcome />
  </Modal>
);

const LoginModal = () => {
  const [currEmail, setCurrEmail] = useState('');

  return (
    <Modal>
      {currEmail ? (
        <VerifyEmail email={currEmail} />
      ) : (
        <Login setCurrEmail={setCurrEmail} />
      )}
    </Modal>
  );
};

const SignupModal = ({ email }: { email?: string }) => {
  const loc = useLocation();
  const state = loc.state as any;

  const [currEmail, setCurrEmail] = useState(loc.state ? state.email : email);

  return (
    <Modal>
      {currEmail ? (
        <VerifyEmail email={currEmail} />
      ) : (
        <Signup setCurrEmail={setCurrEmail} />
      )}
    </Modal>
  );
};

export { WelcomeModal, LoginModal, SignupModal };
