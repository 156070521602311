import { Form, Formik, FormikProps, yupToFormErrors } from 'formik';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { cache } from '../..';
import {
  useCreateCalendarMutation,
  useDeleteCalendarMutation,
  useModifyCalendarMutation,
} from '../../graphql/mutations';
import {
  CalendarAccess,
  useLazyCalendarById,
  useMyCalendars,
} from '../../graphql/queries';
import useStatus from '../../hooks/useStatus';
import {
  CheckmarkIcon,
  ClipboardIcon,
  EnterButtonRight,
  OutlineTrashIcon,
  XButton,
} from '../../Icons';
import { calendarClientValidationSchema } from '../../schemas/calendarSchema';
import { onSubmitHandleError } from '../onSubmitHandleError';
import { DangerButton, GrayButton, PrimaryButton } from '../utilities/buttons';
import {
  NotificationBanner,
  SelectSharing,
  TextField,
} from '../utilities/formfields';
import { copyTextToClipboard } from './utilities';

interface FormState {
  title: string;
  description: string;
  addedUsers: string[];
  unsharedUsers: string[];
  sharing: CalendarAccess;
}

const createInitialValues: FormState = {
  title: '',
  description: '',
  addedUsers: [],
  unsharedUsers: [],
  sharing: 'OWNER',
};

const SpecifiedUsersDisplay = ({
  existingUsers,
  formik,
  formState,
}: {
  existingUsers: string[];
  formik: FormikProps<FormState>;
  formState: FormState;
}) => {
  return (
    <div className="">
      {formState.addedUsers.map((email) => (
        <div className="flex items-center my-2" key={email}>
          <button
            type="button"
            onClick={() =>
              formik.setFieldValue(
                'addedUsers',
                formState.addedUsers.filter((e) => e !== email),
              )
            }
          >
            <OutlineTrashIcon />
          </button>
          <span className="font-light text-body ml-3">{email}</span>
        </div>
      ))}
      {existingUsers.map((email) => (
        <div className="flex items-center my-2" key={email}>
          <button
            type="button"
            onClick={() => {
              if (formState.unsharedUsers.includes(email)) {
                formik.setFieldValue(
                  'unsharedUsers',
                  formState.unsharedUsers.filter((e) => e !== email),
                );
              } else {
                formik.setFieldValue(
                  'unsharedUsers',
                  formState.unsharedUsers.concat(email),
                );
              }
            }}
          >
            <OutlineTrashIcon />
          </button>
          <span
            className={`font-light text-body ml-3 ${
              formState.unsharedUsers.includes(email) ? 'line-through' : ''
            }`}
          >
            {email}
          </span>
        </div>
      ))}
    </div>
  );
};

const LinkDisplay = ({ calendarMiniId }: { calendarMiniId: string }) => {
  const { status, setNewStatus } = useStatus();

  const link =
    process.env.NODE_ENV === 'production'
      ? `https://www.resercate.com/${calendarMiniId}`
      : `http://localhost:3000/${calendarMiniId}`;

  return (
    <div>
      <TextField
        label="Link"
        name="link"
        value={link}
        placeholder={link}
        disabled={true}
      >
        <button
          type="button"
          className="absolute right-1"
          onClick={() =>
            copyTextToClipboard(link).then(
              () => {
                setNewStatus({ type: 'success' }, 3000);
              },
              () => setNewStatus({ type: 'failure' }, 3000),
            )
          }
        >
          {status && status.type === 'success' ? (
            <CheckmarkIcon w="30" h="30" />
          ) : status && status.type === 'failure' ? (
            <XButton w="20" h="30" />
          ) : (
            <ClipboardIcon />
          )}
        </button>
      </TextField>
    </div>
  );
};

const CreateCalendar = ({ mode }: { mode: 'create' | 'modify' }) => {
  const selectedCalendarId = useParams().calendarId;
  const location = useLocation();
  const {
    data: myCalendars,
    loading: myCalendarsLoading,
    error: myCalendarsError,
  } = useMyCalendars();
  const [getCalendar, { data: calendarData, loading: calendarLoading }] =
    useLazyCalendarById();

  const [createCalendar] = useCreateCalendarMutation();
  const [modifyCalendar] = useModifyCalendarMutation();
  const [deleteCalendar] = useDeleteCalendarMutation();
  const navigate = useNavigate();
  const { status, setNewStatus } = useStatus();

  if (calendarLoading || myCalendarsLoading) return <></>;

  if (myCalendarsError) return <h1>Data couldn't be loaded</h1>;

  if (mode === 'modify' && selectedCalendarId && !calendarData) {
    getCalendar({ variables: { miniId: selectedCalendarId } });
  }

  let existingUsers: string[] = [];
  let initialValues = createInitialValues;
  let otherCalendarTitles = myCalendars?.myCalendars.map((c) => c.title);
  if (mode === 'modify' && selectedCalendarId) {
    let initialCalendar = calendarData?.calendarByMiniId;

    if (!initialCalendar) return <></>;

    existingUsers = initialCalendar.specifiedUsers.map((u) => u.email);

    initialValues = {
      title: initialCalendar.title,
      description: initialCalendar.description,
      addedUsers: [],
      unsharedUsers: [],
      sharing: initialCalendar.access,
    };

    otherCalendarTitles = otherCalendarTitles?.filter(
      (t) => t !== initialCalendar!.title,
    );
  }

  return (
    <div className="px-8">
      <h1 className="text-h1 text-xl font-bold text-center mt-2 mb-2">
        {mode === 'create' ? 'Create a calendar' : 'Calendar settings'}
      </h1>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          try {
            calendarClientValidationSchema.validateSync(values, {
              abortEarly: false,
              context: {
                calendarNames: otherCalendarTitles,
              },
            });
          } catch (err) {
            return yupToFormErrors(err);
          }
          return {};
        }}
        onSubmit={async (values, submitProps) => {
          onSubmitHandleError<typeof values>(
            async () => {
              let newCalendarMiniId;
              if (mode === 'create') {
                const result = await createCalendar({
                  variables: {
                    title: values.title,
                    description: values.description,
                    addedUsers: values.addedUsers,
                    sharing: values.sharing,
                  },
                });
                newCalendarMiniId = result.data?.createCalendar.miniId;
              } else if (selectedCalendarId) {
                await modifyCalendar({
                  variables: {
                    title: values.title,
                    description: values.description,
                    addedUsers: values.addedUsers,
                    unsharedUsers: values.unsharedUsers,
                    sharing: values.sharing,

                    calendarMiniId: selectedCalendarId,
                  },
                });
              }

              if (mode === 'create' && !newCalendarMiniId) {
                setNewStatus(
                  {
                    type: 'failure',
                    text: `Server couldn't ${mode} event`,
                  },
                  5000,
                );
              } else {
                navigate(mode === 'create' ? `/${newCalendarMiniId}` : '/');
              }
            },
            setNewStatus,
            submitProps,
          );
        }}
      >
        {(formik) => {
          let sharingComponent;
          if (formik.values.sharing === 'OWNER') {
            sharingComponent = <></>;
          } else if (formik.values.sharing === 'SPECIFIED_USERS') {
            sharingComponent = (
              <Formik
                initialValues={{ addUser: '' }}
                validationSchema={Yup.object({
                  addUser: Yup.string()
                    .required('Email is required')
                    .email('Email address is invalid'),
                })}
                onSubmit={() => {}}
              >
                {(formik2) => {
                  return (
                    <div>
                      <TextField
                        label="Add user"
                        name="addUser"
                        type="text"
                        placeholder="email@gmail.com"
                        disabled={formik.isSubmitting}
                      >
                        <button
                          type="submit"
                          className="absolute right-1"
                          onClick={(e) => {
                            e.preventDefault();

                            formik2.validateField('addUser');
                            if (!formik2.errors.addUser) {
                              formik.setFieldValue(
                                'addedUsers',
                                formik.values.addedUsers.concat(
                                  formik2.values.addUser,
                                ),
                              );
                              formik2.setFieldValue('addUser', '');
                              formik2.setFieldTouched('addUser', false);
                            } else {
                              formik2.setFieldTouched('addUser', true);
                            }
                          }}
                        >
                          <EnterButtonRight />
                        </button>
                      </TextField>
                      <SpecifiedUsersDisplay
                        formik={formik}
                        existingUsers={existingUsers}
                        formState={formik.values}
                      />
                    </div>
                  );
                }}
              </Formik>
            );
          } else if (formik.values.sharing === 'LINK' && mode === 'modify') {
            sharingComponent = (
              <LinkDisplay
                calendarMiniId={selectedCalendarId ? selectedCalendarId : '?'}
              />
            );
          }

          return (
            <Form className="pb-4">
              <TextField
                label="Title"
                name="title"
                type="text"
                placeholder="Title"
                disabled={formik.isSubmitting}
              />
              <TextField
                label="Short description"
                name="description"
                type="text"
                placeholder="Description"
                disabled={formik.isSubmitting}
              />
              <SelectSharing name="sharing" disabled={formik.isSubmitting} />
              {sharingComponent}

              <NotificationBanner notificationInfo={status} />

              {mode === 'modify' && (
                <DangerButton
                  text="Delete"
                  customButtonClasses="px-8 block mx-auto mt-2"
                  type="button"
                  onClick={async () => {
                    onSubmitHandleError<typeof formik.values>(async () => {
                      if (selectedCalendarId) {
                        const res = await deleteCalendar({
                          variables: { calendarMiniId: selectedCalendarId },
                        });

                        if (res.data) {
                          cache.evict({
                            id: `Calendar:${selectedCalendarId}`,
                            broadcast: false,
                          });
                          cache.gc();
                          navigate('/');
                        }
                      }
                    }, setNewStatus);
                  }}
                />
              )}

              <div className="grid grid-cols-2 gap-4 mt-8 mb-3">
                <GrayButton
                  text="Back"
                  onClick={() => {
                    navigate(
                      location.state ? (location.state as any).from : '../',
                    );
                    window.history.replaceState({}, document.title);
                  }}
                  disabled={formik.isSubmitting}
                />
                <PrimaryButton
                  text={mode === 'create' ? 'Create' : 'Save'}
                  disabled={formik.isSubmitting}
                  type="submit"
                  status={formik.isSubmitting ? 'loading' : 'default'}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateCalendar;
