import { FieldHookConfig, useField } from 'formik';
import { useState } from 'react';
import { BasicLocationResults, CalendarAccess } from '../../graphql/queries';
import { NotificationInfo } from '../../hooks/useStatus';
import { CheckmarkIcon, ShieldIcon, WarningIcon } from '../../Icons';
import { MultipleFieldErrors } from '../../schemas/signupSchema';
import { LocationButton, SharingButton } from './buttons';

interface NotificationBannerProps {
  notificationInfo?: NotificationInfo;
}

interface MySelectProps {
  labelText: string;
}

interface SelectLocationProps {
  locations: BasicLocationResults[];
  disabled?: boolean;
}

interface TextFieldProps {
  label: String;
  disabled?: boolean;
  activated?: boolean;
  success?: boolean;
  setFocused?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface InstantFeedbackFieldProps {
  possibleErrors: string[];
  activated?: boolean;
  protectedField?: boolean;
  name: string;
}

const FieldError = ({
  text = 'a',
  invisible,
}: {
  text?: string;
  invisible?: boolean;
}) => {
  return (
    <p
      className={`text-dangerText text-xs text-left mt-1 ${
        invisible ? 'invisible' : 'block'
      }`}
    >
      {invisible ? 'a' : text}
    </p>
  );
};

const MultiFieldError = ({
  errorIs,
  possibleErrors,
}: {
  errorIs: MultipleFieldErrors;
  possibleErrors: string[];
}) => {
  let correct = [];
  let incorrect = [];
  for (let i = 0; i < possibleErrors.length; i++) {
    let className = 'text-xs ';
    const title = possibleErrors[i];
    if (errorIs.includes(i)) {
      className += 'text-dangerText';
      incorrect.push(
        <div className="flex items-center gap-2 my-1" key={title}>
          <ShieldIcon strokeColor="stroke-danger" />
          <span className={className}>{title}</span>
        </div>,
      );
    } else {
      className += 'text-success';
      correct.push(
        <div className="flex items-center gap-2 my-1" key={title}>
          <ShieldIcon strokeColor="stroke-success" />
          <span className={className}>{title}</span>
        </div>,
      );
    }
  }

  const all = incorrect.concat(correct);

  return <div className="text-left mb-4">{all}</div>;
};

const NotificationBanner = ({ notificationInfo }: NotificationBannerProps) => (
  <div
    className={`flex items-center justify-center relative text-white text-xs font-bold text-center py-2 ${
      notificationInfo ? 'block' : 'invisible'
    } ${
      notificationInfo &&
      (notificationInfo.type === 'success' ? 'bg-success' : 'bg-dangerText')
    }`}
  >
    <span className="max-w-[calc(100%-75px)] break-word">
      {notificationInfo ? notificationInfo.text : 'a'}
    </span>

    <div className="absolute left-2">
      {!notificationInfo || notificationInfo.type === 'success' ? (
        <CheckmarkIcon strokeColor="stroke-pureWhite" />
      ) : (
        <WarningIcon />
      )}
    </div>
  </div>
);

const MyTextarea = ({
  label,
  disabled = false,
  success = false,
  activated = true,
  ...props
}: TextFieldProps & FieldHookConfig<string>) => {
  const [field, meta] = useField(props);

  let showErrorMessage =
    activated && meta.touched && meta.error && meta.error !== 'disable';
  const showErrorBorder =
    activated && meta.touched && meta.error && meta.error !== 'disable'
      ? true
      : false;

  return (
    <div className="flex flex-col">
      <label
        htmlFor={props.id || props.name}
        className="text-body2 text-sm text-left my-2"
      >
        {label}
      </label>
      <div className="relative">
        <textarea
          {...field}
          placeholder={props.placeholder}
          disabled={disabled ? true : false}
          className={`w-full ring-1 ring-gray-300 rounded-md px-3 py-1 placeholder:text-sm placeholder:text-body3 ${
            showErrorBorder ? 'drop-shadow-fieldError ring-red-300 ' : ' '
          } ${
            success && !disabled
              ? 'drop-shadow-fieldSuccess ring-teal-400'
              : ' '
          }`}
        />
        {props.children}
      </div>
      <FieldError text={meta.error} invisible={!showErrorMessage}></FieldError>
    </div>
  );
};

const MySelect = ({
  labelText,
  children,
  ...props
}: MySelectProps & FieldHookConfig<string>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helper] = useField(props);

  let showErrorMessage = meta.touched && meta.error && meta.error !== 'disable';
  const showErrorBorder =
    meta.touched && meta.error && meta.error !== 'disable' ? true : false;

  const errorBorder = showErrorBorder ? ' ring-2 ring-red-300' : '';
  return (
    <div className="flex flex-col">
      <label className="text-body2 text-sm text-left my-2">{labelText}</label>
      <div className={'flex gap-y-3 gap-x-6 flex-wrap p-1' + errorBorder}>
        {children}
      </div>
      <FieldError text={meta.error} invisible={!showErrorMessage}></FieldError>
    </div>
  );
};

const SelectLocation = ({
  locations,
  disabled = false,
  ...props
}: SelectLocationProps & FieldHookConfig<string>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helper] = useField(props);

  return (
    <MySelect labelText="Location" {...props}>
      {locations.map((l, i) => (
        <LocationButton
          text={l.name}
          color={l.color}
          onClick={() => {
            if (meta.value === i.toString()) {
              helper.setValue('');
            } else {
              helper.setValue(i.toString());
            }
            helper.setTouched(true, false);
          }}
          pressed={meta.value !== i.toString()}
          disabled={disabled}
          key={l.id}
        />
      ))}
    </MySelect>
  );
};

interface SharingButtonDetails {
  text: string;
  sharing: CalendarAccess;
}

const buttonDetails: SharingButtonDetails[] = [
  {
    text: 'No one',
    sharing: 'OWNER',
  },
  {
    text: 'Specified users',
    sharing: 'SPECIFIED_USERS',
  },
  {
    text: 'Anyone with link',
    sharing: 'LINK',
  },
];

const SelectSharing = ({
  disabled = false,
  ...props
}: FieldHookConfig<string>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helper] = useField(props);

  return (
    <MySelect labelText="Sharing" {...props}>
      <div className="grid grid-cols-3 gap-2">
        {buttonDetails.map(({ text, sharing }) => (
          <SharingButton
            text={text}
            disabled={disabled}
            pressed={meta.value !== sharing}
            onClick={() => {
              helper.setValue(sharing);
            }}
            key={text}
          />
        ))}
      </div>
    </MySelect>
  );
};

const TextField = ({
  label,
  disabled = false,
  success = false,
  activated = true,
  setFocused,
  ...props
}: TextFieldProps & FieldHookConfig<string>) => {
  const [field, meta, helper] = useField(props);

  let showErrorMessage =
    activated && meta.touched && meta.error && meta.error !== 'disable';

  const showErrorBorder =
    activated && meta.touched && meta.error && meta.error !== 'disable'
      ? true
      : false;
  const error = meta.error as any;
  if (error instanceof Array) {
    // hack to get multifielderror working
    showErrorMessage = false;
  }

  return (
    <div className="flex flex-col">
      <label
        htmlFor={props.id || props.name}
        className="text-body2 text-sm text-left my-2"
      >
        {label}
      </label>
      <div className="relative">
        <input
          {...field}
          type={props.type}
          placeholder={props.placeholder}
          disabled={disabled ? true : false}
          className={`w-full ring-1 ring-gray-300 rounded-md px-3 py-1 placeholder:text-sm placeholder:text-body3 ${
            showErrorBorder ? 'drop-shadow-fieldError ring-red-300 ' : ' '
          } ${
            success && !disabled
              ? 'drop-shadow-fieldSuccess ring-teal-400'
              : ' '
          }`}
          onFocus={() => {
            if (setFocused) setFocused(true);
          }}
          onBlur={(e) => {
            if (setFocused) setFocused(false);
            field.onBlur(e);
          }}
          onChange={(e) => {
            if (props.type === 'date') {
              helper.setTouched(true);
            }
            field.onChange(e);
          }}
        />
        {props.children}
      </div>
      <FieldError text={meta.error} invisible={!showErrorMessage}></FieldError>
    </div>
  );
};

const ProtectedField = (props: TextFieldProps & FieldHookConfig<string>) => {
  const [show, setShow] = useState(false);

  return (
    <TextField {...props} type={show ? 'text' : 'password'}>
      <div className="absolute right-2 top-[calc(50%-10px)] flex items-center gap-1.5">
        <span className="text-gray-500 text-xs">Show</span>
        <button
          type="button"
          onClick={() => setShow((prev) => !prev)}
          className="border-[1px] w-5 h-5 border-body3 rounded-md flex items-center justify-center"
          disabled={props.disabled}
        >
          {show && <CheckmarkIcon />}
        </button>
      </div>
    </TextField>
  );
};

const InstantFeedbackField = ({
  possibleErrors,
  activated = true,
  protectedField = false,
  ...props
}: InstantFeedbackFieldProps & TextFieldProps & FieldHookConfig<string>) => {
  const [focused, setFocused] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta] = useField(props);
  const errors = meta.error as any as MultipleFieldErrors;

  return (
    <div>
      {protectedField ? (
        <ProtectedField
          {...props}
          setFocused={setFocused}
          success={activated && !errors}
          activated={activated}
        />
      ) : (
        <TextField
          {...props}
          setFocused={setFocused}
          success={activated && !errors}
          activated={activated}
        />
      )}
      {activated && (focused || meta.touched) && (
        <MultiFieldError
          errorIs={errors ? errors : []}
          possibleErrors={possibleErrors}
        />
      )}
    </div>
  );
};

export {
  MyTextarea,
  SelectLocation,
  SelectSharing,
  TextField,
  FieldError,
  NotificationBanner,
  ProtectedField,
  InstantFeedbackField,
};
