import { gql } from '@apollo/client';

export const BASIC_USER_DETAILS = gql`
  fragment BasicUserDetails on User {
    id
    accountState
    ... on FullUser {
      username
      fullName
      email
      userSettings {
        timeZone
      }
    }
    ... on VerifyUser {
      username
      fullName
      email
    }
  }
`;

export const BASIC_EVENT_DETAILS = gql`
  fragment BasicEventDetails on Event {
    id
    title
    startDate
    endDate
    names

    location {
      id

      name
      color
      miniId
    }

    calendar {
      id
      miniId
    }

    owner {
      id
    }
  }
`;

export const BASIC_LOCATION_DETAILS = gql`
  fragment BasicLocationDetails on Location {
    id
    name
    location
    address
    description

    popularity
    color

    calendar {
      id
      title
      owner {
        id
      }
      miniId
    }
    miniId
  }
`;

export const BASIC_CALENDAR_DETAILS = gql`
  fragment BasicCalendarDetails on Calendar {
    id
    title
    description
    specifiedUsers {
      ... on FullUser {
        email
      }
      ... on VerifyUser {
        email
      }
    }
    access
    miniId

    events {
      ...BasicEventDetails
    }
    locations {
      ...BasicLocationDetails
    }

    owner {
      id
      ... on FullUser {
        username
      }
      ... on VerifyUser {
        username
      }
    }
    miniId
  }
  ${BASIC_EVENT_DETAILS}
  ${BASIC_LOCATION_DETAILS}
`;
