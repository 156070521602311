import * as Yup from 'yup';

const calendarSchema = {
  title: Yup.string()
    .required('Title is required')
    .min(3, 'Minimum of 3 characters')
    .max(30, 'Maximum of 30 characters'),
  description: Yup.string()
    .required('Description is required')
    .min(3, 'Minimum of 3 characters')
    .max(100, 'Maximum of 100 characters'),
};

const calendarClientValidationSchema = Yup.object({
  ...calendarSchema,
  title: calendarSchema.title.test(
    'unique value',
    'A calendar with the same title already exists',
    function test(value) {
      return this.options.context && value
        ? this.options.context.calendarNames.every(
            (n: string) => n.toLowerCase() !== value.toLowerCase(),
          )
        : false;
    },
  ),
});

const calendarServerCreateValidationSchema = Yup.object({
  ...calendarSchema,
  addedUsers: Yup.array()
    .of(Yup.string().defined())
    .required('Added users required'),
  sharing: Yup.mixed<'OWNER' | 'SPECIFIED_USERS' | 'LINK'>().required(
    'Sharing type required',
  ),
});

const calendarServerModifyValidationSchema = Yup.object({
  ...calendarSchema,
  addedUsers: Yup.array()
    .of(Yup.string().defined())
    .required('Added users required'),
  unsharedUsers: Yup.array()
    .of(Yup.string().defined())
    .required('Unshared users required'),
  sharing: Yup.mixed<'OWNER' | 'SPECIFIED_USERS' | 'LINK'>().required(
    'Sharing type required',
  ),
  calendarMiniId: Yup.string().required('Calendar mini id required'),
});

const calendarServerDeleteValidationSchema = Yup.object({
  calendarMiniId: Yup.string().required('Calendar mini id required'),
});

export {
  calendarClientValidationSchema,
  calendarServerCreateValidationSchema,
  calendarServerModifyValidationSchema,
  calendarServerDeleteValidationSchema,
};
