import { gql, useMutation } from '@apollo/client';
import {
  BASIC_CALENDAR_DETAILS,
  BASIC_EVENT_DETAILS,
  BASIC_LOCATION_DETAILS,
  BASIC_USER_DETAILS,
} from './fragments';
import {
  AccountState,
  BasicCalendarResults,
  BasicEventResults,
  BasicLocationResults,
  CalendarAccess,
  ME,
  MeUserResults,
  MY_CALENDARS,
} from './queries';

interface CreateGuestDetails {}

interface CreateGuestResults {
  value: string;
}

interface CreateGuestResultsNamed {
  createGuest: CreateGuestResults;
}

const CREATE_GUEST = gql`
  mutation createGuest {
    createGuest {
      value
    }
  }
`;

export const useCreateGuestMutation = () => {
  return useMutation<CreateGuestResultsNamed, CreateGuestDetails>(CREATE_GUEST);
};

interface CreateUserDetails {
  username: string;
  password: string;
  fullName: string;
  email: string;
}

interface CreateUserResults {
  accountState: AccountState;
  email: string;
}

interface CreateUserResultsNamed {
  createUser: CreateUserResults;
}

const CREATE_USER = gql`
  mutation createUser(
    $username: String!
    $password: String!
    $fullName: String!
    $email: String!
  ) {
    createUser(
      username: $username
      password: $password
      fullName: $fullName
      email: $email
    ) {
      accountState
      ... on FullUser {
        email
      }
      ... on VerifyUser {
        email
      }
    }
  }
`;

export const useCreateUserMutation = () => {
  return useMutation<CreateUserResultsNamed, CreateUserDetails>(CREATE_USER);
};

interface VerifyCodeDetails {
  code: string;
}

interface VerifyCodeResultsNamed {
  verifyCode: boolean;
}

const VERIFY_CODE = gql`
  mutation verifyCode($code: String!) {
    verifyCode(code: $code)
  }
`;

export const useVerifyCodeMutation = () => {
  return useMutation<VerifyCodeResultsNamed, VerifyCodeDetails>(VERIFY_CODE);
};

interface NewCodeDetails {
  email: string;
}

interface NewCodeResultsNamed {
  newCode: boolean;
}

const NEW_CODE = gql`
  mutation newCode($email: String!) {
    newCode(email: $email)
  }
`;

export const useNewCodeMutation = () => {
  return useMutation<NewCodeResultsNamed, NewCodeDetails>(NEW_CODE);
};

interface ModifyUserDetails {
  fullName: string;
  timeZone: string;
  oldPassword?: string;
  password?: string;
}

interface ModifyUserResultsNamed {
  modifyUser: MeUserResults;
}

const MODIFY_USER = gql`
  mutation modifyUser(
    $fullName: String!
    $timeZone: String!
    $oldPassword: String
    $password: String
  ) {
    modifyUser(
      fullName: $fullName
      timeZone: $timeZone
      oldPassword: $oldPassword
      password: $password
    ) {
      ...BasicUserDetails
    }
  }
  ${BASIC_USER_DETAILS}
`;

export const useModifyUserMutation = () => {
  return useMutation<ModifyUserResultsNamed, ModifyUserDetails>(MODIFY_USER);
};

interface LoginDetails {
  email: string;
  password: string;
}

interface LoginResults {
  value: string;
}

interface LoginResultsNamed {
  login: LoginResults;
}

const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      value
    }
  }
`;

export const useLoginMutation = () => {
  return useMutation<LoginResultsNamed, LoginDetails>(LOGIN);
};

interface CreateEventDetails {
  title: string;
  startDate: number;
  endDate: number;
  names: string;

  locationMiniId: string;
  calendarMiniId: string;
}

interface CreateEventResultsNamed {
  createEvent: BasicEventResults;
}

const CREATE_EVENT = gql`
  mutation createEvent(
    $title: String!
    $startDate: Date!
    $endDate: Date!
    $names: String!
    $locationMiniId: ID!
    $calendarMiniId: ID!
  ) {
    createEvent(
      title: $title
      startDate: $startDate
      endDate: $endDate
      names: $names
      locationMiniId: $locationMiniId
      calendarMiniId: $calendarMiniId
    ) {
      ...BasicEventDetails
    }
  }
  ${BASIC_EVENT_DETAILS}
`;

export const useCreateEventMutation = () => {
  return useMutation<CreateEventResultsNamed, CreateEventDetails>(
    CREATE_EVENT,
    {
      update(cache, { data }) {
        if (data?.createEvent) {
          cache.modify({
            id: `Calendar:${data.createEvent.calendar.id}`,
            fields: {
              events(existingEvents = []) {
                const newEventRef = cache.writeFragment({
                  data: data.createEvent,
                  fragment: BASIC_EVENT_DETAILS,
                });
                return [...existingEvents, newEventRef];
              },
            },
          });
        }
      },
    },
  );
};

interface ModifyEventDetails {
  title: string;
  startDate: number;
  endDate: number;
  names: string;

  locationMiniId: string;
  eventId: string;
}

interface ModifyEventResultsNamed {
  modifyEvent: BasicEventResults;
}

const MODIFY_EVENT = gql`
  mutation modifyEvent(
    $title: String!
    $startDate: Date!
    $endDate: Date!
    $names: String!
    $locationMiniId: ID!
    $eventId: ID!
  ) {
    modifyEvent(
      title: $title
      startDate: $startDate
      endDate: $endDate
      names: $names
      locationMiniId: $locationMiniId
      eventId: $eventId
    ) {
      ...BasicEventDetails
    }
  }
  ${BASIC_EVENT_DETAILS}
`;

export const useModifyEventMutation = () => {
  return useMutation<ModifyEventResultsNamed, ModifyEventDetails>(MODIFY_EVENT);
};

interface DeleteEventDetails {
  eventId: string;
}

interface DeleteEventResults {
  id: string;
  events: { id: string };
}

interface DeleteEventResultsNamed {
  deleteEvent: DeleteEventResults;
}

const DELETE_EVENT = gql`
  mutation deleteEvent($eventId: String!) {
    deleteEvent(eventId: $eventId) {
      id
      events {
        id
      }
    }
  }
  ${BASIC_CALENDAR_DETAILS}
`;

export const useDeleteEventMutation = () => {
  return useMutation<DeleteEventResultsNamed, DeleteEventDetails>(DELETE_EVENT);
};

interface CreateCalendarDetails {
  title: string;
  description: string;
  addedUsers: string[];
  sharing: CalendarAccess;
}

interface CreateCalendarResultsNamed {
  createCalendar: BasicCalendarResults;
}

const CREATE_CALENDAR = gql`
  mutation createCalendar(
    $title: String!
    $description: String!
    $addedUsers: [String!]!
    $sharing: CalendarAccess!
  ) {
    createCalendar(
      title: $title
      description: $description
      addedUsers: $addedUsers
      sharing: $sharing
    ) {
      ...BasicCalendarDetails
    }
  }
  ${BASIC_CALENDAR_DETAILS}
`;

export const useCreateCalendarMutation = () => {
  return useMutation<CreateCalendarResultsNamed, CreateCalendarDetails>(
    CREATE_CALENDAR,
    { refetchQueries: [{ query: MY_CALENDARS }] },
  );
};

interface ModifyCalendarDetails {
  title: string;
  description: string;
  addedUsers: string[];
  unsharedUsers: string[];
  sharing: CalendarAccess;

  calendarMiniId: string;
}

interface ModifyCalendarResultsNamed {
  modifyCalendar: BasicCalendarResults;
}

const MODIFY_CALENDAR = gql`
  mutation modifyCalendar(
    $title: String!
    $description: String!
    $addedUsers: [String!]!
    $unsharedUsers: [String!]!
    $sharing: CalendarAccess!
    $calendarMiniId: ID!
  ) {
    modifyCalendar(
      title: $title
      description: $description
      addedUsers: $addedUsers
      unsharedUsers: $unsharedUsers
      sharing: $sharing
      calendarMiniId: $calendarMiniId
    ) {
      ...BasicCalendarDetails
    }
  }
  ${BASIC_CALENDAR_DETAILS}
`;

export const useModifyCalendarMutation = () => {
  return useMutation<ModifyCalendarResultsNamed, ModifyCalendarDetails>(
    MODIFY_CALENDAR,
  );
};

interface DeleteCalendarDetails {
  calendarMiniId: string;
}

interface DeleteCalendarResultsNamed {
  deleteCalendar: { id: string };
}

const DELETE_CALENDAR = gql`
  mutation deleteCalendar($calendarMiniId: ID!) {
    deleteCalendar(calendarMiniId: $calendarMiniId)
  }
`;

export const useDeleteCalendarMutation = () => {
  return useMutation<DeleteCalendarResultsNamed, DeleteCalendarDetails>(
    DELETE_CALENDAR,
    { refetchQueries: [{ query: ME }, { query: MY_CALENDARS }] },
  );
};

interface CreateLocationDetails {
  name: string;
  location: string;
  address: string;
  description: string;

  color: string;

  calendarMiniId: string;
}

interface CreateLocationResultsNamed {
  createLocation: BasicLocationResults;
}

const CREATE_LOCATION = gql`
  mutation createLocation(
    $name: String!
    $location: String!
    $address: String!
    $description: String!
    $color: Color!
    $calendarMiniId: ID!
  ) {
    createLocation(
      name: $name
      location: $location
      address: $address
      description: $description
      color: $color
      calendarMiniId: $calendarMiniId
    ) {
      ...BasicLocationDetails
    }
  }
  ${BASIC_LOCATION_DETAILS}
`;

export const useCreateLocationMutation = () => {
  return useMutation<CreateLocationResultsNamed, CreateLocationDetails>(
    CREATE_LOCATION,
    {
      update(cache, { data }) {
        if (data?.createLocation) {
          cache.modify({
            id: `Calendar:${data.createLocation.calendar.id}`,
            fields: {
              locations(existingLocations = []) {
                const newLocationRef = cache.writeFragment({
                  data: data.createLocation,
                  fragment: BASIC_LOCATION_DETAILS,
                });
                return [...existingLocations, newLocationRef];
              },
            },
          });
        }
      },
    },
  );
};

interface ModifyLocationDetails {
  name: string;
  location: string;
  address: string;
  description: string;

  color: string;

  locationMiniId: string;
  calendarMiniId: string;
}

interface ModifyLocationResultsNamed {
  modifyLocation: BasicLocationResults;
}

const MODIFY_LOCATION = gql`
  mutation modifyLocation(
    $name: String!
    $location: String!
    $address: String!
    $description: String!
    $color: Color!
    $locationMiniId: ID!
    $calendarMiniId: ID!
  ) {
    modifyLocation(
      name: $name
      location: $location
      address: $address
      description: $description
      color: $color
      locationMiniId: $locationMiniId
      calendarMiniId: $calendarMiniId
    ) {
      ...BasicLocationDetails
    }
  }
  ${BASIC_LOCATION_DETAILS}
`;

export const useModifyLocationMutation = () => {
  return useMutation<ModifyLocationResultsNamed, ModifyLocationDetails>(
    MODIFY_LOCATION,
  );
};

interface DeleteLocationDetails {
  locationMiniId: string;
  calendarMiniId: string;
}

interface DeleteLocationResults {
  id: string;
  events: { id: string };
  locations: { id: string };
}

interface DeleteLocationResultsNamed {
  deleteLocation: DeleteLocationResults;
}

const DELETE_LOCATION = gql`
  mutation deleteLocation($locationMiniId: ID!, $calendarMiniId: ID!) {
    deleteLocation(
      locationMiniId: $locationMiniId
      calendarMiniId: $calendarMiniId
    ) {
      id
      events {
        id
      }
      locations {
        id
      }
    }
  }
  ${BASIC_CALENDAR_DETAILS}
`;

export const useDeleteLocationMutation = () => {
  return useMutation<DeleteLocationResultsNamed, DeleteLocationDetails>(
    DELETE_LOCATION,
  );
};
