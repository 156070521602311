import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { cache } from '../..';
import {
  useCreateEventMutation,
  useDeleteEventMutation,
  useModifyEventMutation,
} from '../../graphql/mutations';
import { useLazyEventById, useLocations } from '../../graphql/queries';
import useStatus from '../../hooks/useStatus';
import {
  DoubleChevronDown,
  FilledCheckmarkIcon,
  FilledTrashIcon,
  FilledXIcon,
} from '../../Icons';
import { eventClientValidationSchema } from '../../schemas/eventSchema';
import { onSubmitHandleError } from '../onSubmitHandleError';
import { PrimaryButton } from '../utilities/buttons';
import {
  FieldError,
  NotificationBanner,
  SelectLocation,
  TextField,
} from '../utilities/formfields';
import {
  setActiveDrawer,
  setSelectedEventId,
  useUISelector,
} from './CalendarRedux';

const createInitialValues = {
  title: '',
  startDate: new Date().toISOString().substring(0, 10),
  startTime: '',
  endDate: new Date().toISOString().substring(0, 10),
  endTime: '',
  names: '',
  selectedLocationI: '',
};

const splitDateTime = (d: Date) => {
  const justDate = d.toLocaleDateString('en-CA');
  const justTime = d.toLocaleTimeString('en-GB').substring(0, 5);

  return { justDate, justTime };
};

const CreateEvent = ({
  mode,
  selectedCalendarId,
}: {
  mode: 'create' | 'modify';
  selectedCalendarId: string;
}) => {
  const navigate = useNavigate();
  const [getEvent, { data: eventData, loading: eventLoading }] =
    useLazyEventById();

  const [createEvent] = useCreateEventMutation();
  const [modifyEvent] = useModifyEventMutation();
  const [deleteEvent] = useDeleteEventMutation();
  const {
    data: locationsData,
    loading,
    error,
  } = useLocations({ miniId: selectedCalendarId });

  const { selectedEventId } = useUISelector((state) => state.ui);
  const dispatch = useDispatch();
  const [minimized, setMinimized] = useState(false);
  const display = minimized ? 'hidden' : 'block';
  const { status, setNewStatus } = useStatus();

  if (loading || eventLoading) return <></>;
  if (!locationsData || error) return <h1>Data couldn't be loaded</h1>;
  const locations = locationsData.calendarByMiniId.locations;

  if (mode === 'modify' && selectedEventId && !eventData) {
    getEvent({ variables: { eventId: selectedEventId } });
  }

  let initialValues = createInitialValues;
  if (mode === 'modify') {
    let initialEvent = eventData?.eventById;
    if (!initialEvent) return <></>;

    const { justDate: startDate, justTime: startTime } = splitDateTime(
      new Date(initialEvent.startDate),
    );
    const { justDate: endDate, justTime: endTime } = splitDateTime(
      new Date(initialEvent.endDate),
    );

    const locationI = locations.findIndex(
      (l) => l.id === initialEvent?.location.id,
    );

    initialValues = {
      title: initialEvent.title,
      startDate,
      startTime,
      endDate,
      endTime,
      names: initialEvent.names,
      selectedLocationI: locationI === -1 ? '' : locationI.toString(),
    };
  }

  return (
    <div className="relative">
      <h1 className="text-h1 text-center text-xl font-bold">
        {`${mode === 'create' ? 'Create' : 'Modify'} a reservation`}
      </h1>
      <button
        type="button"
        onClick={() => setMinimized((prev) => !prev)}
        className={`absolute top-0 right-0 ${minimized ? 'rotate-180' : ''}`}
      >
        <DoubleChevronDown />
      </button>
      <Formik
        initialValues={initialValues}
        validationSchema={eventClientValidationSchema}
        onSubmit={(values, submitProps) => {
          onSubmitHandleError<typeof values>(
            async () => {
              const startDate = new Date(
                Number(values.startDate.substring(0, 4)),
                Number(values.startDate.substring(5, 7)) - 1,
                Number(values.startDate.substring(8, 10)),
                Number(values.startTime.substring(0, 2)),
                Number(values.startTime.substring(3, 5)),
              );

              const endDate = new Date(
                Number(values.endDate.substring(0, 4)),
                Number(values.endDate.substring(5, 7)) - 1,
                Number(values.endDate.substring(8, 10)),
                Number(values.endTime.substring(0, 2)),
                Number(values.endTime.substring(3, 5)),
              );

              if (
                Number(values.selectedLocationI) < 0 ||
                Number(values.selectedLocationI) >= locations.length
              ) {
                throw new Error('Invalid location i');
              }
              const locationId =
                locations[Number(values.selectedLocationI)].miniId;

              if (mode === 'create') {
                await createEvent({
                  variables: {
                    title: values.title,
                    startDate: startDate.getTime(),
                    endDate: endDate.getTime(),
                    names: values.names,
                    locationMiniId: locationId,
                    calendarMiniId: selectedCalendarId,
                  },
                });
              } else {
                if (selectedEventId) {
                  await modifyEvent({
                    variables: {
                      title: values.title,
                      startDate: startDate.getTime(),
                      endDate: endDate.getTime(),
                      names: values.names,
                      locationMiniId: locationId,
                      eventId: selectedEventId,
                    },
                  });
                }
              }

              dispatch(setActiveDrawer('none'));
            },
            setNewStatus,
            submitProps,
          );
        }}
      >
        {(formik) => {
          const BottomActionsCreate = () => (
            <button
              type="button"
              onClick={() => dispatch(setActiveDrawer('none'))}
              disabled={formik.isSubmitting}
            >
              <FilledTrashIcon />
            </button>
          );

          const BottomActionsModify = () => (
            <div className="flex items-center gap-4">
              <button
                type="button"
                onClick={() => {
                  dispatch(setSelectedEventId(undefined));
                  dispatch(setActiveDrawer('none'));
                }}
                disabled={formik.isSubmitting}
              >
                <FilledXIcon />
              </button>
              <button
                type="button"
                onClick={async () => {
                  onSubmitHandleError<typeof formik.values>(async () => {
                    if (selectedEventId) {
                      const res = await deleteEvent({
                        variables: { eventId: selectedEventId },
                      });

                      if (res.data) {
                        cache.evict({
                          id: `Event:${selectedEventId}`,
                          broadcast: false,
                        });
                        cache.gc();
                        dispatch(setSelectedEventId(undefined));
                        dispatch(setActiveDrawer('none'));
                      }
                    }
                  }, setNewStatus);
                }}
                disabled={formik.isSubmitting}
              >
                <FilledTrashIcon />
              </button>
            </div>
          );

          return (
            <Form className="w-full">
              <div className={display}>
                <TextField
                  label="Title"
                  name="title"
                  type="text"
                  placeholder="Reservation title"
                  disabled={formik.isSubmitting}
                />
              </div>
              <div className="grid grid-cols-2 gap-x-10">
                <TextField
                  label="Start date"
                  name="startDate"
                  type="date"
                  disabled={formik.isSubmitting}
                />
                <TextField
                  label="Start time"
                  name="startTime"
                  type="time"
                  disabled={formik.isSubmitting}
                />
                <TextField
                  label="End date"
                  name="endDate"
                  type="date"
                  disabled={formik.isSubmitting}
                />
                <TextField
                  label="End time"
                  name="endTime"
                  type="time"
                  disabled={formik.isSubmitting}
                />
              </div>
              {minimized && (
                <FieldError
                  text="More errors below..."
                  invisible={
                    formik.errors.title === undefined &&
                    formik.errors.names === undefined &&
                    formik.errors.selectedLocationI === undefined &&
                    !formik.status
                  }
                />
              )}
              <div className={display}>
                <TextField
                  label="Name(s)"
                  name="names"
                  type="text"
                  placeholder="First Last, ..."
                  disabled={formik.isSubmitting}
                />
                {locations.length ? (
                  <SelectLocation
                    locations={locations}
                    name="selectedLocationI"
                    disabled={formik.isSubmitting}
                  />
                ) : (
                  <>
                    <label className="text-body2 text-sm text-left my-2">
                      Locations
                    </label>
                    <br />
                    <PrimaryButton
                      text="Create your first location here"
                      onClick={() => navigate('locations/newlocation')}
                      customButtonStyle={{
                        paddingLeft: '1rem',
                        paddingRight: '1rem',
                        paddingTop: '0.5rem',
                        paddingBottom: '0.5rem',
                      }}
                    />
                  </>
                )}
              </div>
              <div className={display}>
                <NotificationBanner notificationInfo={status} />
              </div>

              <div className="flex flex-row justify-between my-2 mx-4">
                {mode === 'create' ? (
                  <BottomActionsCreate />
                ) : (
                  <BottomActionsModify />
                )}
                <button type="submit">
                  {formik.isSubmitting ? (
                    <span className="animate-spin w-8 h-8 border-[3px] border-pureWhite border-b-buttonSecondary rounded-full inline-block ml-2"></span>
                  ) : (
                    <FilledCheckmarkIcon />
                  )}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateEvent;
