const MenuIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.24615 1H16.6718M1.24615 7H16.6718M1.24615 13H16.6718"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke-iconBlack"
    />
  </svg>
);

const CalendarIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8256 0H15.9333V3H18.8256V0ZM21.7179 0H20.2718V4.5H14.4872V0H8.70256V4.5H2.91794V0H1.47179H1.47082C0.672548 0 0.0256348 0.671 0.0256348 1.499V1.5V22.5V22.501C0.0256348 23.329 0.672548 24 1.47082 24H1.47179H21.7179H21.7189C22.5172 24 23.1641 23.329 23.1641 22.501V22.5V1.5V1.499C23.1641 0.671 22.5172 0 21.7189 0H21.7179ZM20.2718 21H2.91794V7.5H20.2718V21ZM7.2564 0H4.3641V3H7.2564V0ZM13.041 10.5H10.1487V13.5H13.041V10.5ZM17.3795 10.5H14.4872V13.5H17.3795V10.5ZM8.70256 15H5.81025V18H8.70256V15ZM8.70256 10.5H5.81025V13.5H8.70256V10.5ZM13.041 15H10.1487V18H13.041V15ZM17.3795 15H14.4872V18H17.3795V15Z"
      className="fill-h1"
    />
  </svg>
);

const CogIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.32463 2.31731C8.75103 0.560897 11.249 0.560897 11.6754 2.31731C11.9508 3.45193 13.2507 3.99038 14.2478 3.38285C15.7913 2.44239 17.5576 4.2087 16.6172 5.75218C16.0096 6.74925 16.5481 8.04918 17.6827 8.32463C19.4391 8.75103 19.4391 11.249 17.6827 11.6754C16.5481 11.9508 16.0096 13.2507 16.6172 14.2478C17.5576 15.7913 15.7913 17.5576 14.2478 16.6172C13.2507 16.0096 11.9508 16.5481 11.6754 17.6827C11.249 19.4391 8.75103 19.4391 8.32463 17.6827C8.04918 16.5481 6.74926 16.0096 5.75219 16.6172C4.2087 17.5576 2.44239 15.7913 3.38285 14.2478C3.99038 13.2507 3.45193 11.9508 2.31731 11.6754C0.560897 11.249 0.560897 8.75103 2.31731 8.32463C3.45193 8.04918 3.99037 6.74926 3.38285 5.75218C2.44239 4.2087 4.2087 2.44239 5.75219 3.38285C6.74926 3.99037 8.04918 3.45193 8.32463 2.31731Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke-iconBlack"
    />
    <path
      d="M13 10C13 11.6569 11.6569 13 10 13C8.34315 13 7 11.6569 7 10C7 8.34315 8.34315 7 10 7C11.6569 7 13 8.34315 13 10Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke-iconBlack"
    />
  </svg>
);

const LocationIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 19V3C17 1.89543 16.1046 1 15 1H5C3.89543 1 3 1.89543 3 3V19M17 19L19 19M17 19H12M3 19L1 19M3 19H8M7 4.99998H8M7 8.99998H8M12 4.99998H13M12 8.99998H13M8 19V14C8 13.4477 8.44772 13 9 13H11C11.5523 13 12 13.4477 12 14V19M8 19H12"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke-iconBlack"
    />
  </svg>
);

const FilterIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 2C1 1.73478 1.10157 1.48043 1.28238 1.29289C1.46318 1.10536 1.70841 1 1.9641 1H17.3897C17.6454 1 17.8907 1.10536 18.0715 1.29289C18.2523 1.48043 18.3538 1.73478 18.3538 2V4.586C18.3538 4.85119 18.2522 5.10551 18.0714 5.293L11.8876 11.707C11.7068 11.8945 11.6052 12.1488 11.6051 12.414V15L7.74872 19V12.414C7.74866 12.1488 7.64705 11.8945 7.46624 11.707L1.28248 5.293C1.10167 5.10551 1.00005 4.85119 1 4.586V2Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke-iconBlack"
    />
  </svg>
);

const FilledPlusIcon = () => (
  <div className="relative">
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 39.2001C30.6039 39.2001 39.2 30.6039 39.2 20C39.2 9.39618 30.6039 0.800049 20 0.800049C9.39612 0.800049 0.799988 9.39618 0.799988 20C0.799988 30.6039 9.39612 39.2001 20 39.2001ZM22.4 12.8C22.4 11.4746 21.3255 10.4 20 10.4C18.6745 10.4 17.6 11.4746 17.6 12.8V17.6H12.8C11.4745 17.6 10.4 18.6746 10.4 20C10.4 21.3255 11.4745 22.4 12.8 22.4H17.6V27.2C17.6 28.5255 18.6745 29.6 20 29.6C21.3255 29.6 22.4 28.5255 22.4 27.2V22.4H27.2C28.5255 22.4 29.6 21.3255 29.6 20C29.6 18.6746 28.5255 17.6 27.2 17.6H22.4V12.8Z"
        className="fill-buttonPrimary"
      />
    </svg>
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 39.2C9.41322 39.2 0.799988 30.5868 0.799988 20C0.799988 9.41328 9.41322 0.800049 20 0.800049C30.5868 0.800049 39.2 9.41328 39.2 20C39.2 30.5868 30.5868 39.2 20 39.2Z"
        fill="white"
      />
    </svg>
  </div>
);

const CheckmarkIcon = ({
  strokeColor = 'stroke-gray-800',
  w = '24',
  h = '24',
}: {
  strokeColor?: string;
  w?: string;
  h?: string;
}) => (
  <svg
    width={w}
    height={h}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 13L9 17L19 7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={strokeColor}
    />
  </svg>
);

const WarningIcon = () => (
  <svg
    width="24"
    height="21"
    viewBox="0 0 24 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25694 3.09882C9.02154 1.73952 10.9786 1.73952 11.7432 3.09882L17.3235 13.0194C18.0735 14.3526 17.11 15.9999 15.5804 15.9999H4.41978C2.89013 15.9999 1.9267 14.3526 2.67663 13.0194L8.25694 3.09882ZM11 13C11 13.5523 10.5523 14 10 14C9.44772 14 9 13.5523 9 13C9 12.4477 9.44772 12 10 12C10.5523 12 11 12.4477 11 13ZM10 5C9.44772 5 9 5.44772 9 6V9C9 9.55228 9.44772 10 10 10C10.5523 10 11 9.55228 11 9V6C11 5.44772 10.5523 5 10 5Z"
      fill="white"
    />
  </svg>
);

const ShieldIcon = ({ strokeColor }: { strokeColor: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="flex-shrink-0"
  >
    <path
      d="M9 12L11 14L15 10M20.6179 5.98434C20.4132 5.99472 20.2072 5.99997 20 5.99997C16.9265 5.99997 14.123 4.84453 11.9999 2.94434C9.87691 4.84446 7.07339 5.99985 4 5.99985C3.79277 5.99985 3.58678 5.9946 3.38213 5.98422C3.1327 6.94783 3 7.95842 3 9.00001C3 14.5915 6.82432 19.2898 12 20.622C17.1757 19.2898 21 14.5915 21 9.00001C21 7.95847 20.8673 6.94791 20.6179 5.98434Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={strokeColor}
    />
  </svg>
);

const PlaneIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4472 8.89444C15.786 8.72505 16 8.37878 16 8.00001C16 7.62124 15.786 7.27498 15.4472 7.10558L1.44721 0.105584C1.09251 -0.0717665 0.666526 -0.0227602 0.361364 0.230502C0.0562019 0.483764 -0.0704689 0.893423 0.038476 1.27473L1.46704 6.27472C1.5897 6.70402 1.98209 7 2.42857 7H7C7.55229 7 8 7.44772 8 8C8 8.55229 7.55229 9 7 9H2.42857C1.98209 9 1.58971 9.29598 1.46705 9.72528L0.038476 14.7253C-0.0704689 15.1066 0.0562019 15.5163 0.361364 15.7695C0.666526 16.0228 1.09251 16.0718 1.44721 15.8944L15.4472 8.89444Z"
      fill="white"
    />
  </svg>
);

const XButton = ({
  w = '20',
  h = '16',
}: {
  strokeColor?: string;
  w?: string;
  h?: string;
}) => (
  <svg
    width={w}
    height={h}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 13.75L13 1.25M1 1.25L13 13.75"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke-danger"
    />
  </svg>
);

const HomeIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 12L5 10M5 10L12 3L19 10M5 10V20C5 20.5523 5.44772 21 6 21H9M19 10L21 12M19 10V20C19 20.5523 18.5523 21 18 21H15M9 21C9.55228 21 10 20.5523 10 20V16C10 15.4477 10.4477 15 11 15H13C13.5523 15 14 15.4477 14 16V20C14 20.5523 14.4477 21 15 21M9 21H15"
      stroke="#111827"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ThreeBoxesIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 17V7M9 17C9 18.1046 8.10457 19 7 19H5C3.89543 19 3 18.1046 3 17V7C3 5.89543 3.89543 5 5 5H7C8.10457 5 9 5.89543 9 7M9 17C9 18.1046 9.89543 19 11 19H13C14.1046 19 15 18.1046 15 17M9 7C9 5.89543 9.89543 5 11 5H13C14.1046 5 15 5.89543 15 7M15 17V7M15 17C15 18.1046 15.8954 19 17 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5H17C15.8954 5 15 5.89543 15 7"
      stroke="#111827"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const AccountIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.12104 17.8037C7.15267 16.6554 9.4998 16 12 16C14.5002 16 16.8473 16.6554 18.879 17.8037M15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
      stroke="#111827"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const LogoutIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 16L7 12M7 12L11 8M7 12L21 12M16 16V17C16 18.6569 14.6569 20 13 20H6C4.34315 20 3 18.6569 3 17V7C3 5.34315 4.34315 4 6 4H13C14.6569 4 16 5.34315 16 7V8"
      stroke="#111827"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const SearchIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
      stroke="#D1D5DB"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const FilledCloseIcon = () => (
  <svg
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5668 13.8596C14.3715 13.6644 14.0549 13.6644 13.8596 13.8596C13.6644 14.0549 13.6644 14.3715 13.8596 14.5667L17.7487 18.4558L13.8596 22.3449C13.6644 22.5402 13.6644 22.8567 13.8596 23.052C14.0549 23.2473 14.3715 23.2473 14.5668 23.052L18.4558 19.1629L22.3449 23.052C22.5402 23.2473 22.8568 23.2473 23.052 23.052C23.2473 22.8567 23.2473 22.5402 23.052 22.3449L19.163 18.4558L23.052 14.5667C23.2473 14.3715 23.2473 14.0549 23.052 13.8596C22.8568 13.6644 22.5402 13.6644 22.3449 13.8596L18.4558 17.7487L14.5668 13.8596ZM30.8302 6.08144C37.6644 12.9156 37.6644 23.996 30.8302 30.8302C23.996 37.6644 12.9157 37.6644 6.08148 30.8302C-0.7527 23.996 -0.7527 12.9156 6.08148 6.08144C12.9157 -0.752733 23.996 -0.752733 30.8302 6.08144Z"
      fill="#D1D5DB"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const FilledTrashIcon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1216_1816)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1567 0C13.263 0 12.446 0.428005 12.0463 1.10557L10.339 4H2.35945C1.05636 4 0 4.89543 0 6C0 7.10457 1.05636 8 2.35945 8L2.35945 28C2.35945 30.2091 4.47217 32 7.07834 32H25.9539C28.5601 32 30.6728 30.2091 30.6728 28V8C31.9759 8 33.0323 7.10457 33.0323 6C33.0323 4.89543 31.9759 4 30.6728 4H22.6932L20.9859 1.10557C20.5863 0.428005 19.7693 0 18.8756 0H14.1567ZM9.43779 12C9.43779 10.8954 10.4941 10 11.7972 10C13.1003 10 14.1567 10.8954 14.1567 12V24C14.1567 25.1046 13.1003 26 11.7972 26C10.4941 26 9.43779 25.1046 9.43779 24V12ZM21.235 10C19.9319 10 18.8756 10.8954 18.8756 12V24C18.8756 25.1046 19.9319 26 21.235 26C22.5381 26 23.5945 25.1046 23.5945 24V12C23.5945 10.8954 22.5381 10 21.235 10Z"
        fill="#F87171"
      />
    </g>
    <defs>
      <clipPath id="clip0_1216_1816">
        <rect width="33.0323" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const FilledCheckmarkIcon = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37 19C37 28.9411 28.9411 37 19 37C9.05887 37 1 28.9411 1 19C1 9.05887 9.05887 1 19 1C28.9411 1 37 9.05887 37 19Z"
      fill="#2DD4BF"
    />
    <path
      d="M13 19L17 23L25 15M37 19C37 28.9411 28.9411 37 19 37C9.05887 37 1 28.9411 1 19C1 9.05887 9.05887 1 19 1C28.9411 1 37 9.05887 37 19Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const DoubleChevronDown = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 13L12 20L5 13M19 5L12 12L5 5"
      stroke="#111827"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ChevronLeft = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 19L8 12L15 5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke-gray-600"
    />
  </svg>
);

const EnterButtonRight = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.25 11.25L20 15M20 15L16.25 18.75M20 15L10 15M26.25 15C26.25 21.2132 21.2132 26.25 15 26.25C8.7868 26.25 3.75 21.2132 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15Z"
      stroke="#111827"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const OutlineTrashIcon = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5833 7.58333L19.6437 20.7377C19.5627 21.8715 18.6193 22.75 17.4826 22.75H8.51742C7.3807 22.75 6.43725 21.8715 6.35626 20.7377L5.41666 7.58333M10.8333 11.9167V18.4167M15.1667 11.9167V18.4167M16.25 7.58333V4.33333C16.25 3.73502 15.765 3.25 15.1667 3.25H10.8333C10.235 3.25 9.75 3.73502 9.75 4.33333V7.58333M4.33333 7.58333H21.6667"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke-red-400"
    />
  </svg>
);

const ClipboardIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 5H6C4.89543 5 4 5.89543 4 7V19C4 20.1046 4.89543 21 6 21H16C17.1046 21 18 20.1046 18 19V18M8 5C8 6.10457 8.89543 7 10 7H12C13.1046 7 14 6.10457 14 5M8 5C8 3.89543 8.89543 3 10 3H12C13.1046 3 14 3.89543 14 5M14 5H16C17.1046 5 18 5.89543 18 7V10M20 14H10M10 14L13 11M10 14L13 17"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke-body"
    />
  </svg>
);

const FilledXIcon = () => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 37.7999C30.2784 37.7999 37.8 30.2783 37.8 21C37.8 11.7216 30.2784 4.19995 21 4.19995C11.7216 4.19995 4.20001 11.7216 4.20001 21C4.20001 30.2783 11.7216 37.7999 21 37.7999ZM18.2849 15.315C17.4648 14.4949 16.1352 14.4949 15.3151 15.315C14.495 16.1351 14.495 17.4648 15.3151 18.2849L18.0302 21L15.3151 23.715C14.495 24.5351 14.495 25.8648 15.3151 26.6849C16.1352 27.505 17.4648 27.505 18.2849 26.6849L21 23.9698L23.7151 26.6849C24.5352 27.505 25.8648 27.505 26.6849 26.6849C27.505 25.8648 27.505 24.5351 26.6849 23.715L23.9699 21L26.6849 18.2849C27.505 17.4648 27.505 16.1351 26.6849 15.315C25.8648 14.4949 24.5352 14.4949 23.7151 15.315L21 18.0301L18.2849 15.315Z"
      className="fill-gray-400"
    />
  </svg>
);

const FilledXIconSmall = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 37.7999C30.2784 37.7999 37.8 30.2783 37.8 21C37.8 11.7216 30.2784 4.19995 21 4.19995C11.7216 4.19995 4.20001 11.7216 4.20001 21C4.20001 30.2783 11.7216 37.7999 21 37.7999ZM18.2849 15.315C17.4648 14.4949 16.1352 14.4949 15.3151 15.315C14.495 16.1351 14.495 17.4648 15.3151 18.2849L18.0302 21L15.3151 23.715C14.495 24.5351 14.495 25.8648 15.3151 26.6849C16.1352 27.505 17.4648 27.505 18.2849 26.6849L21 23.9698L23.7151 26.6849C24.5352 27.505 25.8648 27.505 26.6849 26.6849C27.505 25.8648 27.505 24.5351 26.6849 23.715L23.9699 21L26.6849 18.2849C27.505 17.4648 27.505 16.1351 26.6849 15.315C25.8648 14.4949 24.5352 14.4949 23.7151 15.315L21 18.0301L18.2849 15.315Z"
      className="fill-gray-600"
    />
  </svg>
);

export {
  MenuIcon,
  CalendarIcon,
  CogIcon,
  LocationIcon,
  FilterIcon,
  FilledPlusIcon,
  CheckmarkIcon,
  WarningIcon,
  ShieldIcon,
  PlaneIcon,
  XButton,
  HomeIcon,
  ThreeBoxesIcon,
  AccountIcon,
  LogoutIcon,
  SearchIcon,
  FilledCloseIcon,
  FilledTrashIcon,
  FilledCheckmarkIcon,
  DoubleChevronDown,
  ChevronLeft,
  EnterButtonRight,
  OutlineTrashIcon,
  ClipboardIcon,
  FilledXIcon,
  FilledXIconSmall,
};
