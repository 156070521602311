import * as Yup from 'yup';

const userSettingsValidationSchema = Yup.object({
  fullName: Yup.string()
    .required('Full name is required')
    .min(3, 'Minimum of 3 characters')
    .max(50, 'Maximum of 50 characters'),
  timeZone: Yup.string().required('Time zone is required'),
  oldPassword: Yup.string(),
  password: Yup.string(),
});

export default userSettingsValidationSchema;
