import { useUISelector } from '../Calendar/CalendarRedux';
import Header from '../Header';

const Page = ({ children }: { children: JSX.Element }) => {
  const { showMenu } = useUISelector((state) => state.ui);

  return (
    <div className={showMenu ? 'overflow-auto h-screen' : 'h-screen'}>
      <Header headerType="secondary" />
      {children}
    </div>
  );
};

export default Page;
