import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateGuestMutation } from '../../graphql/mutations';
import { useLazyMyCalendars } from '../../graphql/queries';
import {
  PrimaryButton,
  SecondaryButton,
  TextButtonStatus,
} from '../utilities/buttons';

interface FeatureType {
  title: string;
  description: string;
  imgUrl: string;
}

const Feature = ({ description, imgUrl }: FeatureType) => {
  return (
    <div className="mt-6 mb-2 flex flex-col items-center h-56 md:col-start-1 md:row-start-2">
      <img src={imgUrl} className="my-2 h-36" />
      <p className="text-sm md:text-base font-light text-body">{description}</p>
    </div>
  );
};

const allFeatures: FeatureType[] = [
  {
    title: 'add reservations',
    description:
      'Full control over user reservations: view, modify, or delete reservations',
    imgUrl: 'assets/Welcome/add_reservations.svg',
  },
  {
    title: 'manage reservations',
    description:
      'Add reservations to open spots: book your event in an instant',
    imgUrl: 'assets/Welcome/manage_reservations.svg',
  },
  {
    title: 'create locations',
    description:
      'Create locations for a calendar: users can view and reserve locations',
    imgUrl: 'assets/Welcome/create_locations.svg',
  },
];

const Features = () => {
  const [currentFeatureIndex, setCurrentFeatureIndex] = useState<number>(0);
  const [currTimeout, setCurrTimeout] = useState<NodeJS.Timeout>();

  useEffect(() => {
    clearTimeout(currTimeout);

    const id = setTimeout(
      () => setCurrentFeatureIndex((prev) => (prev + 1) % allFeatures.length),
      5000,
    );
    setCurrTimeout(id);
  }, [currentFeatureIndex]);

  const currentFeature = allFeatures[currentFeatureIndex];
  const buttons = allFeatures.map((feature, i) => (
    <div key={feature.title}>
      <button
        className={`font-light text-body2 tracking-wide ${
          currentFeature.title === feature.title ? 'text-buttonPrimary' : ''
        }`}
        onClick={() => setCurrentFeatureIndex(i)}
      >
        {feature.title}
      </button>
    </div>
  ));

  return (
    <>
      <Feature {...currentFeature} />
      <div className='md:col-start-1 md:row-start-3'>{buttons}</div>
    </>
  );
};

const Welcome = () => {
  const [getCalendars] = useLazyMyCalendars();
  const [createGuest] = useCreateGuestMutation();
  const navigate = useNavigate();
  const [guestButtonStatus, setGuestButtonStatus] =
    useState<TextButtonStatus>('default');

  const handleNewGuest = async () => {
    setGuestButtonStatus('loading');

    try {
      const res = await createGuest();

      if (res.data) {
        localStorage.setItem('sessionId', res.data.createGuest.value);
        localStorage.setItem('guestCreatedAt', new Date().getTime().toString());

        // get new calendar miniId
        const { data, loading, error } = await getCalendars();
        if (loading) return <></>;
        if (error) return <h1>Something went wrong</h1>;

        navigate(`/${data?.myCalendars[0].miniId}`);
      }
    } catch (e) {
    } finally {
      setGuestButtonStatus('default');
    }
  };

  return (
    <div className='w-full md:grid md:gap-4 md:grid-cols-2'>
      <h1 className="text-h1 text-3xl font-bold md:col-span-2 md:mb-6">resercate</h1>
      <h2 className="text-body mt-2 md:col-start-2 md:text-lg">
        The website that helps you create and manage reservations across
        different locations
      </h2>
      <Features />
      <div className="mt-6 mb-3 w-full md:col-start-2 md:row-start-3 md:-top-10 md:relative">
        <PrimaryButton
          onClick={handleNewGuest}
          text="Try without logging in"
          customButtonClasses="w-full !max-w-lg !py-3"
          status={guestButtonStatus}
          disabled={guestButtonStatus === 'loading'}
        />
        <div className="grid grid-cols-2 gap-4 mt-4">
          <SecondaryButton
            onClick={() => {
              navigate('/signup');
            }}
            text="Sign up"
            disabled={guestButtonStatus === 'loading'}
          />
          <SecondaryButton
            onClick={() => {
              navigate('/login');
            }}
            text="Log in"
            disabled={guestButtonStatus === 'loading'}
          />
        </div>
      </div>
    </div>
  );
};

export default Welcome;
