import { FilledXIconSmall } from '../../Icons';
import { useToastSelector } from './ToastRedux';

const Toast = () => {
  const { toastMessage } = useToastSelector((state) => state.toast);

  return (
    <div
      className="hidden absolute bottom-1/4 left-1/2 -translate-x-1/2 z-[100]
               p-4 pr-8 border-2 rounded-lg border-danger bg-pureWhite drop-shadow-xl"
      style={toastMessage ? { display: 'block' } : {}}
    >
      <div className="absolute right-0 top-0">
        <FilledXIconSmall />
      </div>
      <p className="text-dangerText">{toastMessage}</p>
    </div>
  );
};

export default Toast;
