import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { BasicLocationResults, useLocations } from '../../graphql/queries';
import { SearchIcon } from '../../Icons';
import { GrayButton, LocationButton, PrimaryButton } from '../utilities/buttons';
import { setActiveDrawer } from './CalendarRedux';

export interface FilterInfo {
  search: string;
  deselectedLocationIs: number[];
}

interface SearchProps {
  search: string;
  setFilterInfo: React.Dispatch<React.SetStateAction<FilterInfo>>;
}

interface LocationsProps {
  locations: BasicLocationResults[];
  filterInfo: FilterInfo;
  setFilterInfo: React.Dispatch<React.SetStateAction<FilterInfo>>;
}

interface FilterProps {
  filterInfo: FilterInfo;
  setFilterInfo: React.Dispatch<React.SetStateAction<FilterInfo>>;
}

const SearchBar = ({ search, setFilterInfo }: SearchProps) => {
  return (
    <div className="flex flex-col">
      <label htmlFor="search" className="text-body2 text-sm text-left my-2">
        Search
      </label>
      <div className="w-full relative flex items-center">
        <input
          type="text"
          name="search"
          id="search"
          onChange={({ target }) =>
            setFilterInfo((prev) => ({ ...prev, search: target.value }))
          }
          value={search}
          placeholder="Title"
          className="w-full ring-1 ring-gray-300 rounded-md px-3 py-1 placeholder:text-sm placeholder:text-body3"
        />
        <div className="absolute right-2 pointer-events-none">
          <SearchIcon />
        </div>
      </div>
    </div>
  );
};

const LocationsBar = ({
  locations,
  filterInfo,
  setFilterInfo,
}: LocationsProps) => (
  <div className="mt-6">
    <p className="text-body2 text-sm text-left my-2">Locations</p>
    <div className="flex gap-y-3 gap-x-6 flex-wrap">
      {locations.map((l, i) => (
        <LocationButton
          text={l.name}
          color={l.color}
          onClick={() =>
            setFilterInfo((prev) => ({
              ...prev,
              deselectedLocationIs: prev.deselectedLocationIs.includes(i)
                ? prev.deselectedLocationIs.filter((prevI) => prevI !== i)
                : prev.deselectedLocationIs.concat(i),
            }))
          }
          pressed={filterInfo.deselectedLocationIs.includes(i)}
          key={l.id}
        />
      ))}
    </div>
  </div>
);

const Filter = ({ filterInfo, setFilterInfo }: FilterProps) => {
  const selectedCalendarId = useParams().calendarId;
  if (!selectedCalendarId) return <h1>no id in url</h1>;

  const {
    data: locationsData,
    loading,
    error,
  } = useLocations({ miniId: selectedCalendarId });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (loading) return <></>;
  if (!locationsData || error) return <h1>Data couldn't be loaded</h1>;

  return (
    <div className="max-w-3xl mx-auto">
      <div>
        <h1 className="text-h1 text-center text-xl font-bold">Filter</h1>
        <SearchBar search={filterInfo.search} setFilterInfo={setFilterInfo} />
        <LocationsBar
          locations={locationsData.calendarByMiniId.locations}
          filterInfo={filterInfo}
          setFilterInfo={setFilterInfo}
        />
        <GrayButton
          text="Location Details"
          customButtonClasses="w-full bg-gray-50 mt-4"
          onClick={() => navigate('locations')}
        />
      </div>
      <div className="grid grid-cols-2 gap-12 mt-8 mb-2">
        <PrimaryButton
          text="Back"
          onClick={() => dispatch(setActiveDrawer('none'))}
        />
        <GrayButton
          text="Clear"
          onClick={() => {
            setFilterInfo({
              search: '',
              deselectedLocationIs: [],
            });
            dispatch(setActiveDrawer('none'));
          }}
        />
      </div>
    </div>
  );
};

export default Filter;
